import { useEffect } from "react";
import { Steel} from "@/interface/steel";
import { useContext, useState } from "react";
import { getSteelDetail } from "@/api/steel";
import { Form,Select,InputNumber,Upload} from "antd";
import './goods-steel-detail.less';
import { SECTION_PROTO } from "@/enums/section-proto";
interface Iprops {
    steelId: number;
}
export default function GoodsSteelDetail(props: Iprops) {
    const [form] = Form.useForm();
    const [steel, setSteel] = useState<Steel>();
    useEffect(() => {
        getSteelDetail(props.steelId).then(res => {
            setSteel(res.data);
            form.setFieldValue('type',SECTION_PROTO[res.data.type]?.name);
            form.setFieldValue('sectionId',res.data.sectionName);
            form.setFieldValue('matGradeId',res.data.matGradeName);
            form.setFieldValue('steelMillId',res.data.steelMillName);
            form.setFieldValue('specification',res.data.specification);
            form.setFieldValue('price',res.data.price && res.data.price  || '价格面议');
        }).catch(err => {
            console.log(err);
        })
    }, [])
    return (
        <div className="add-steel-dm-container">
        <div className="title">
            {"钢材详情"}
        </div>
        <Form form={form}  autoComplete="false" className="form" disabled={true}>
            <Form.Item label="品种" name="type" >
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(value: any, option) =>
                        option.children.indexOf(value) > -1
                    }
                    notFoundContent={null}>
                </Select>
            </Form.Item>
            <Form.Item label="类型" name="sectionId" >
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(value: any, option) =>
                        option.children.indexOf(value) > -1
                    }
                    notFoundContent={null}>
                    
                </Select>
            </Form.Item>
            <Form.Item label="材质" name="matGradeId" >
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(value: any, option) =>
                        option.children.indexOf(value) > -1
                    }
                    notFoundContent={null}>
                   
                </Select>
            </Form.Item>
            <Form.Item label="钢厂" name="steelMillId" >
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(value: any, option) =>
                        option.children.indexOf(value) > -1
                    }
                    notFoundContent={null}>
                </Select>
            </Form.Item>
            <Form.Item label="库存" name="specification" >
                <InputNumber style={{ width: '100%' }} addonAfter="吨" />
            </Form.Item>
            <Form.Item label="单价" name="price" >
                <InputNumber style={{ width: '100%' }} addonAfter="元/吨" precision={2} />
            </Form.Item>
            <Form.Item label="图片" >
                <Upload accept=".jpg, .png,.jpeg, .gif, .webp"  listType="picture-card" fileList={ [{ uid: '1', url: steel?.url || SECTION_PROTO[steel?.type]?.image, name: "img.png", status: "done" }] } >
                </Upload>
            </Form.Item>
           
        </Form>
    </div>
    )
}