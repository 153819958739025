import './App.less';
import { Navigate, Route, useNavigate } from 'react-router-dom';
import MainLayout from './layouts/test';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React, { useEffect } from 'react';
import { Routes } from 'react-router-dom/dist';
import Code from './routes/login/code';
import Login from './routes/login/login';
import ForgetPwdPage from './routes/login/forget';
import cookieStore from './store/cookie-store';
import { checkLogin } from './api/user';
import { User } from './interface/user';
import SupplierList from './routes/supplier/main';
import SupplierDetail from './routes/supplier-detail/detail';
import { UploadFileState, UploadPanel, useUploadService } from 'tncet-common';
import { getFileByMd5, getPresignedPutObjectUrl } from './api/file';
import DownloadApp from './routes/download-page';

export const UploadFileContext = React.createContext<UploadFileState>(null);

function App() {
    const navigate = useNavigate();
    useEffect(() => {
        const checkTimer = setInterval(() => {
            checkLogin()
                .then(res => {
                    let userDetail: User = res.data;
                    cookieStore.setLoginId(userDetail.id);
                }).catch(err => {
                    console.log(err);
                    cookieStore.clear();
                    if (window.location.pathname != '/app-download') {
                        navigate('/code');
                    }
                })
        }, 80 * 1000)// 80秒
        return (() => {
            clearInterval(checkTimer);
        }
        )
    }, [])

    return (
        <ConfigProvider locale={zhCN}>
            <UploadFileContext.Provider value={useUploadService()}>
                <div className="tn-base">
                    <Routes>
                        <Route path={`code`} element={<Code />} />
                        <Route path={`login`} element={<Login />} />
                        <Route path={`forget`} element={<ForgetPwdPage />} />
                        <Route path='/suppliers' element={<SupplierList />} />
                        <Route path='/suppliers/:id' element={<SupplierDetail />}></Route>
                        <Route path='/app-download' element={<DownloadApp />} />
                        <Route path='*' element={<Navigate to='code' />} />
                    </Routes>
                </div>
                <UploadPanel checkFileExists={getFileByMd5} getUploadUrl={getPresignedPutObjectUrl} uploadContext={UploadFileContext} />
            </UploadFileContext.Provider>
        </ConfigProvider>
    )
}
export default App;

