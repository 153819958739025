import { addSteel, deleteSteel, getSteelDetail, getSteels, updateSteel } from "@/api/steel";
import { Steel } from "@/interface/steel";
import { useContext, useEffect, useState } from "react";
import { Button, Image, message, Popover, InputNumber, Space, Select, Form, Input, Upload, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import './goods-steel.less';
import './goods-topline.less';
import { SECTION_PROTO } from "@/enums/section-proto";
import cookieStore from "@/store/cookie-store";
import { Supplier } from "@/interface/supplier";
import { getSupplier } from "@/api/supplier";
import { getFileDownloadUrl } from "@/api/file";
import { FileUploadParam, UploadFileLocale ,DragModal} from "tncet-common";
import { UploadFileContext } from "@/App";
import { queryStandardSections } from "@/api/section";
import { Section } from "@/interface/section";
import { getStandardMaterialQualityGrades } from "@/api/mat-quality-grade";
import { MaterialQualityGrades } from "@/interface/material-quality-grade";
import { getSteelMills } from "@/api/steel-mill";
import { SteelMill } from "@/interface/steel-mill";
import { EnvironmentOutlined, EllipsisOutlined } from '@ant-design/icons'
import GoodsSteelDetail from "./goods-steel-detail";
import { UserType } from "@/enums/user-type";
interface Iprops {
    supplierId: number;
    onTabChange: (number) => any
}
interface PickerProps {
    label: string;
    value: number;
}
export default function GoodsSteel(props: Iprops) {
    const [steels, setSteels] = useState<Steel[]>();
    const user = cookieStore.getUser();
    const [supplier, setSupplier] = useState<Supplier>();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [uploadFile, setUplaodFile] = useState<FileUploadParam>();
    const [selectedSteel, setSelectedSteel] = useState<Steel>();
    const [uploadFileList, setUploadFileList] = useState<any>([]);
    const uploadFileContext = useContext(UploadFileContext);
    const [cmVis, setCmVis] = useState<boolean>(false);
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);
    const [contactId, setContactId] = useState<number>();
    const [curSteelId, setCurSteelId] = useState<number>();
    const [cmTop, setCmTop] = useState<number>();
    const [cmLeft, setCmLeft] = useState<number>();
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [dmVis, setDmVis] = useState<boolean>(false);
    const [detail, setDetail] = useState<boolean>(false);
    const [dmMode, setDmMode] = useState<string>("add"); // 用于记录弹窗是用于新增还是修改
    const [selectedProto, setSelectedProto] = useState<number>(1); // 下拉菜单选中的截面的类型
    const [protos, setProtos] = useState<PickerProps[]>(); // 截面类型列表
    const [sections, setSections] = useState<PickerProps[]>(); // 截面列表
    const [selectedSection, setSelectedSection] = useState<number>(); // 下拉菜单选中的具体截面的id
    const [secMats, setSecMats] = useState<PickerProps[]>(); // TODO 材质列表
    const [steelMills, setSteelMills] = useState<PickerProps[]>(); // TODO 钢厂列表
    const [loading, setLoading] = useState<boolean>(false);
    const [itemType, setitemType] = useState<number>(1)
    const [canRelease, setcanRelease] = useState<boolean>(false)


    useEffect(() => {
        getSupplier(props.supplierId).then(res => {
            if (res.status === 200) {
                let sup = res.data;
                setSupplier(sup);
                setcanRelease(user.id === res.data.userId ||user.type === UserType.ADMIN)
                setContactId(res.data.userId);
            }
        })
    }, [])
    useEffect(() => {
        generateSteels();
    }, [])
    useEffect(() => {
        if (!!selectedProto) {
            queryStandardSections({ type: selectedProto }).then(res => {
                let originSecs: Section[] = res.data || [];
                let tmpArr: PickerProps[] = [];
                originSecs.map(sec => {
                    tmpArr.push({
                        label: sec.name,
                        value: sec.id
                    })
                })
                setSections(tmpArr);
                setSelectedSection(tmpArr[0].value);
            })
        }
        generateData();
    }, [selectedProto])

    const generateData = () => {
        let tmpArr: PickerProps[] = [];
        Object.keys(SECTION_PROTO).map(key => {
            tmpArr.push({
                label: SECTION_PROTO[key].name,
                value: parseInt(key),
            })
        })
        setProtos(tmpArr);
        getStandardMaterialQualityGrades().then(res => {
            let grades: MaterialQualityGrades[] = res.data || [];
            let tmpGrades: PickerProps[] = [];
            grades.forEach(grade => {
                tmpGrades.push({
                    label: grade.name,
                    value: grade.id
                })
            })
            setSecMats(tmpGrades);
        })
        getSteelMills().then(res => {
            let mills: SteelMill[] = res.data || [];
            let tmpMills: PickerProps[] = [];
            mills.forEach(mill => {
                tmpMills.push({
                    label: mill.name,
                    value: mill.id
                })
            })
            setSteelMills(tmpMills);
        })
    }
    const generateSteels = () => {
        getSteels(props?.supplierId).then(res => {
            if (res.status === 200) {
                let steels: Steel[] = res.data || [];
                setSteels(steels);
            }
        })
    }
    const onAddFinish = (values) => {
        let newSteel: Steel = {
            ...values,
            deleteFile: isDeleteImage,
        }

        if (!!uploadFile) {
            newSteel['file'] = {
                fileMD5: uploadFile.md5,
                suffix: uploadFile.suffix,
                filePath: uploadFile.filePath,
                fileSize: uploadFile.fileSize,
            };
        }
        setLoading(true);
        if (dmMode === "add") {
            if (user?.id === supplier.userId) {
                addSteel(props.supplierId, newSteel).then(res => {
                    if (res.status === 200) {
                        generateSteels();
                        message.success("添加成功");
                        setDmVis(false);
                        generateSteels();
                    } else {
                        message.error("添加失败");
                    }
                }).catch(e => {
                    message.error("添加失败");
                })
            }
            else {
                message.success("您没有添加权限");
            }
        }
        else if (dmMode === "edit") {
            if (user?.id === supplier.userId) {
                updateSteel(selectedSteel.id, newSteel).then(res => {
                    if (res.status === 200) {
                        message.success("更新成功");
                        setDmVis(false);
                        generateSteels();
                    } else {
                        message.error("更新失败");
                    }
                }).catch(e => {
                    message.error("更新失败");
                })
            }
            else {
                message.success("您没有更新权限");
            }
        }

    }

    const onSteelDelete = (id: number) => {
        if (user?.id === supplier.userId || user.type === 0) {
            setDeleteLoading(true);
            deleteSteel(id).then(res => {
                if (res.status === 200) {
                    message.success("删除成功");
                    let arr = steels?.filter(item => item.id !== id);
                    setSteels(arr);
                } else {
                    message.error("删除失败");
                }
                setDeleteLoading(false);
            }).catch(e => {
                message.error("删除失败");
                setDeleteLoading(false);
            })
        }
        else {
            message.success("您没有删除权限");
        }

    }

    const uploadCallbackFn = (uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
            getFileDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                setUplaodFile(uploadFile);
                setSelectedSteel({
                    ...selectedSteel,
                    md5: uploadFile?.md5,
                    suffix: uploadFile?.suffix,
                    url: res.data,
                })
                resolve(true);
            })
        })
    }

    useEffect(() => {
        if (uploadFileList.length === 0) return;
        let files: UploadFileLocale[] = uploadFileList.map(item => {
            return {
                file: item,
                callbackFn: uploadCallbackFn
            }
        })
        uploadFileContext.startUpload(files); //上传的关键
        setUploadFileList([]);
    }, [uploadFileList])

    const onOpenDm = (ope: string, row?: Steel) => {
        setSelectedSteel({ ...row });
        if (ope === "add") {
            setDmMode("add");
            form.resetFields();
        } else if (ope === "edit") {
            setDmMode("edit");
            form.setFieldsValue(
                {
                    ...row,
                }
            );

        }
        setDmVis(true);
    }
    const beforeUpload = (file, fileList) => {
        setCmVis(false);
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        let isImage = file && acceptedImageTypes.includes(file.type);
        if (!isImage) {
            message.warning("只能上传图片！")
        } else {
            if (uploadFileList.length === 0) {
                setUploadFileList([...fileList]);
            }
        }
        return false;
    }

    const onRemoveImage = () => {
        let tmp = selectedSteel;
        tmp.md5 = null;
        tmp.suffix = null;
        tmp.url = null;
        setSelectedSteel({ ...tmp });
        setUplaodFile(null);
        setUploadFileList([]);
        setIsDeleteImage(true);
    }

    const onImageRightClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let clientX = e.clientX;
        let clientY = e.clientY;
        setCmLeft(clientX);
        setCmTop(clientY);
        setCmVis(true);
    }

    
    function GoodsTopLine() {
        return (
            <div className="goods-topLine">
                <div className="titleBox">

                    <span className="title">
                        {supplier?.name}
                    </span>
                </div>
                <div className="filterBox">
                    <span className="filterTxt">商品类型：</span>
                    <div className={itemType === 1 ? "filterItem filterItemChosen" : "filterItem"}
                        onClick={() => { setitemType(1); props.onTabChange(1) }}>
                        <span className="filterTxt">钢材</span>
                    </div>
                    <div className={itemType === 2 ? "filterItem filterItemChosen" : "filterItem"}
                        onClick={() => { setitemType(2); props.onTabChange(2) }}>
                        <span className="filterTxt">油漆</span>
                    </div>
                    <div className={itemType === 3 ? "filterItem filterItemChosen" : "filterItem"}
                        onClick={() => { setitemType(3); props.onTabChange(3) }}>
                        <span className="filterTxt">防火涂料</span>
                    </div>
                </div>
                <div className="releaseBox">
                    {canRelease && <Button className="release" onClick={() => onOpenDm("add")}>
                        <span>上架</span>
                    </Button>}
                </div>
            </div>
        )
    }
    function SteelEntry(props: { data: Steel }) {
        const [PopoverOn, setPopoverOn] = useState<boolean>(false);
        return (
            <div className="steelEntry" >
                <div className="imageBox" onContextMenu={onImageRightClick}>
                    <Image width={81} height={75} className="image" src={props.data.url || SECTION_PROTO[props.data.type].image} />
                </div>
                <div className="rightBox" >
                    <div className="infoLine">
                        <div className="infoLine-left" >
                            <span>

                                <div className="item-sectionName">{props.data.sectionName}</div>
                                <div className="item-matGradeName">{props.data.matGradeName}</div>
                                <div className="item-add">
                                    <EnvironmentOutlined />{props.data.steelMillAbbreviation}</div>

                            </span>
                        </div>

                        <span >

                        </span>

                        <Popover
                            placement="right"
                            content={<div>
                                <div className="xxx" style={{ width: '100%', marginTop: '-10px' }}
                                    onClick={() => onOpenDm("edit", props.data)}>
                                    <Button style={{ border: 0 }}>编辑</Button>
                                </div>
                                <div style={{ marginBottom: '-10px' }}
                                    onClick={() => { onSteelDelete(props.data.id) }}>
                                    <Button style={{ border: 0, color: 'red' }}>下架</Button>
                                </div>
                            </div>}
                            //   title="Title"
                            trigger="click"
                            open={PopoverOn}
                            onOpenChange={(e) => { setPopoverOn(e) }}

                        >
                            {canRelease && <EllipsisOutlined style={{ fontSize: "24px" }} onClick={() => { }} />}
                        </Popover>
                    </div>
                    <div className="priceLine" onClick={() => { setDetail(true); setCurSteelId(props.data.id) }}>
                        <span>
                            {props.data.price && props.data.price + '元/吨' || '价格面议'}
                        </span>

                    </div>

                </div>
            </div>
        )
    }
    return (
        <>
            <div className="goods-steel">
                <GoodsTopLine />
                <div className="steel-list">
                    {
                        steels?.map(data => (
                            <div key={data?.id}>
                                <SteelEntry data={data} />
                            </div>
                           
                        ))
                    }
                </div>
            </div>
            <DragModal
                visible={dmVis}
                width={800}
                onClose={() => { setDmVis(false) }}
                destroyOnClose
                mask
            >
                <div className="add-steel-dm-container">
                    <div className="title">
                        {dmMode === "add" ? "添加钢材" : "编辑钢材"}
                    </div>
                    <Form form={form} onFinish={onAddFinish} autoComplete="false" className="form" >
                        <Form.Item label="品种" name="type" rules={[{ required: true, message: "品种不能为空" }]}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(value: any, option) =>
                                    option.children.indexOf(value) > -1
                                }
                                notFoundContent={null}>
                                {protos?.map((org: PickerProps) => (
                                    <Select.Option value={org.value} key={org.value}>{org.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="类型" name="sectionId" rules={[{ required: true, message: "类型不能为空" }]}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(value: any, option) =>
                                    option.children.indexOf(value) > -1
                                }
                                notFoundContent={null}>
                                {sections?.map((org: PickerProps) => (
                                    <Select.Option value={org.value} key={org.value}>{org.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="材质" name="matGradeId" rules={[{ required: true, message: "材质不能为空" }]}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(value: any, option) =>
                                    option.children.indexOf(value) > -1
                                }
                                notFoundContent={null}>
                                {secMats?.map((org: PickerProps) => (
                                    <Select.Option value={org.value} key={org.value}>{org.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="钢厂" name="steelMillId" rules={[{ required: true, message: "钢厂不能为空" }]}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(value: any, option) =>
                                    option.children.indexOf(value) > -1
                                }
                                notFoundContent={null}>
                                {steelMills?.map((org: PickerProps) => (
                                    <Select.Option value={org.value} key={org.value}>{org.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="库存" name="specification" rules={[{ required: true, message: "规格不能为空" }]}>
                            <InputNumber style={{ width: '100%' }} addonAfter="吨" />
                        </Form.Item>
                        <Form.Item label="单价" name="price" >
                            <InputNumber style={{ width: '100%' }} addonAfter="元/吨" precision={2} />
                        </Form.Item>
                        <Form.Item label="图片" >
                            <Upload accept=".jpg, .png,.jpeg, .gif, .webp" beforeUpload={beforeUpload} listType="picture-card" fileList={!!selectedSteel?.url ? [{ uid: '1', url: selectedSteel?.url, name: "img.png", status: "done" }] : []} onRemove={onRemoveImage}>
                                {!selectedSteel?.url && <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传图片</div>
                                </div>}
                            </Upload>
                        </Form.Item>
                        <div className="footer">
                            <Space>
                                <Button onClick={() => setDmVis(false)}>取消</Button>
                                <Button type="primary" htmlType="submit" style={{ background: '#1271FF' }}>确定</Button>

                            </Space>
                        </div>
                    </Form>
                </div>
            </DragModal>
            <DragModal
                visible={detail}
                width={800}
                onClose={() => { setDetail(false) }}
                mask
                destroyOnClose
            >
                <div className="steel-detail">
                    <GoodsSteelDetail steelId={curSteelId}></GoodsSteelDetail>
                </div>

            </DragModal>
        </>
    )
}
