import { User } from '../interface/user';
import Cookies from 'js-cookie'

export const GLOBAL_NAMESPACE = 'tnsection';
export const TOKEN = `${GLOBAL_NAMESPACE}_login_ticket`;
export const USER = `${GLOBAL_NAMESPACE}_user_meta`;
export const USER_ID = `${GLOBAL_NAMESPACE}_loginid`;
export const USER_UUID = `${GLOBAL_NAMESPACE}_uuid`;

class CookieStore {

    set(key: string, value: any, crossDomain: boolean = true) {
        Cookies.set(key, JSON.stringify(value), {
            domain: !!crossDomain ? document.domain.split('.').slice(-2).join('.') : window.location.host,
        });
    }

    setExpires(key: string, value: any, expires: number, crossDomain: boolean = true) {
        Cookies.set(key, JSON.stringify(value), {
            domain: !!crossDomain ? document.domain.split('.').slice(-2).join('.') : window.location.host,
            expires: expires
        });
    }

    get(key) {
        let value = Cookies.get(key);
        return JSON.parse(value || 'null') || null;
    }

    setToken(token: string) {
        Cookies.set(TOKEN, token, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getToken() {
        return Cookies.get(TOKEN);
    }

    setLoginId(id: number) {
        Cookies.set(USER_ID, `${id}`, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getLoginId() {
        return +Cookies.get(USER_ID) || null;
    }

    setUUID(uuid: string) {
        Cookies.set(USER_UUID, `${uuid}`, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getUUID() {
        return Cookies.get(USER_UUID) || null;
    }

    setUser(user: User) {
        this.setExpires(USER, user, 60);
    }

    getUser() {
        return this.get(USER);
    }

    clear() {
        let domain = document.domain.split('.').slice(-2).join('.');
        Cookies.remove(TOKEN, {
            domain: domain,
        });
        Cookies.remove(USER_ID, {
            domain: domain,
        });
        Cookies.remove(USER, {
            domain: domain,
        });
        Cookies.remove(USER_UUID, {
            domain: domain,
        });
    }
}

export default new CookieStore();
