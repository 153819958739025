import { Alert, Button, Checkbox, Form, Input } from "antd";
import './login.less'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import appStore, { REMEMBER_PASSWORD, REMEMBER_TELEPHONE, REMEMBER_TAG } from "../../store/app-store";
import CryptoJS from 'crypto-js';
import { passwordLogin } from "../../api/user";
import { User } from "../../interface/user";
import cookieStore from "../../store/cookie-store";
import { useNavigate } from "react-router-dom/dist";
import React from "react";
import { number } from "echarts";
import { ok } from "assert";
import { UserType } from "@/enums/user-type";


export const targetUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : `http://project.${document.domain.split('.').slice(-2).join('.')}`;

export default function Login(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    let [errorMessage, setErrorMessage] = useState('');
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        // appStore.remove(REMEMBER_TELEPHONE);
        // appStore.remove(REMEMBER_PASSWORD);
        // appStore.remove(REMEMBER_TAG);
        // 从本地存储中获取自动保存的账户信息        
        let mobile = appStore.get(REMEMBER_TELEPHONE);
        let remember = appStore.get(REMEMBER_TAG);
        let password = appStore.get(REMEMBER_PASSWORD) || '';
        form.setFieldsValue({
            mobile: mobile,
            remember: remember,
            password: CryptoJS.enc.Base64.parse(password).toString(CryptoJS.enc.Utf8),
        });
    }, [])

    const login = (values) => {
        //错误信息清空
        setErrorMessage('');
        setLoading(true);

        let data = {
            mobile: values.mobile,
            password: values.password,
            clientType: "web",
        };
        passwordLogin(data)
            .then(res => {
                // 登录失败
                if (res.status !== 200) {
                    setErrorMessage(res?.message);
                    return;
                }
                setErrorMessage("登录成功");
                let userDetail: User = res.data;

                // 向cookie中存user数据以判断是否为管理员
                cookieStore.setUser(userDetail)

                // appStore.set(AUTHORIZATION, userDetail.token);
                cookieStore.setToken(userDetail.token || '');
                cookieStore.setLoginId(userDetail.id);
                // appStore.set(USER_ID, userDetail.id);
                // userStore.setUserInfo(userDetail);
                if (values.remember) {
                    appStore.set(REMEMBER_TELEPHONE, values.mobile);
                    appStore.set(REMEMBER_PASSWORD, CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(values.password)));
                    appStore.set(REMEMBER_TAG, values.remember);
                } else {
                    appStore.remove(REMEMBER_TELEPHONE);
                    appStore.remove(REMEMBER_PASSWORD);
                    appStore.remove(REMEMBER_TAG);
                }
                // 根据角色跳转
                if (userDetail.type === UserType.ADMIN) {
                    navigate('/suppliers');
                } else {
                    if (!!userDetail.supplier) {
                        navigate('/suppliers/' + userDetail.supplier.id);
                    } else {
                        navigate('/suppliers');
                    }
                }
            }).catch(err => {
                console.log(err)
                setErrorMessage('登录失败');
            }).finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="login-container">
            <Form className="login-form" form={form} onFinish={login} autoComplete="off">
                {!!errorMessage && (
                    <Alert
                        style={{ marginBottom: '10px' }}
                        message={errorMessage}
                        type="error"
                        closable
                        onClose={() => setErrorMessage('')} />
                )}
                <Form.Item name="mobile" rules={[{ required: true, message: '电话号不能为空' }]}>
                    <Input prefix={<UserOutlined />} placeholder="电话号" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                    <Input.Password prefix={<LockOutlined />} placeholder="密码" />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <NavLink className="login-form-code" to={"/code"}>
                        验证码登录
                    </NavLink>
                    <NavLink className="login-form-forgot" to={"/forget"}>
                        忘记密码?
                    </NavLink>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                        登 录
                    </Button>

                </Form.Item>
            </Form>
        </div>
    )
}
