import cookieStore from "@/store/cookie-store";
import { Image } from "antd"
import { useEffect, useState } from "react";
import "./supplier-detail-topbar.less"

import UserDropdown from "@/component/user-dropdown";
import { Supplier } from "@/interface/supplier";
export interface SupplierDetailTopbarProps{
    supplier: Supplier
    onTabChange: (number)=>any
    onTab?: number
}

export default function SupplierDetailTopbar(props: SupplierDetailTopbarProps){
    const [currTab, setCurrTab] = useState<number>(props.onTab || 1)
    

    useEffect(()=>{
        // alert(currTab)
    }, [currTab])

    return <div className="topbarBox">
        <div>
            <div className="logoBox">
                <Image src="/assets/logo.png" preview={false}></Image>
            </div>
            <div className="titleBox">
                <Image src="/assets/快截.png" preview={false} height={32}></Image>
            </div>
            <div className="listBox">
                <Image className="image" src="/assets/商家列表.png" preview={false} width={104} height={30}
                onClick={()=>{window.location.href="/suppliers"}}/>
            </div>
        </div>
        <div>
        <div className={currTab===1?'tabBoxSelecting tabBox':'tabBox'}>
            <span className="tabTxt" onClick={()=>{props.onTabChange(1); setCurrTab(1);}}>商品</span>
            <div className="chooseBar"></div>
        </div>
        <div className={currTab===2?'tabBoxSelecting tabBox':'tabBox'}>
            <span className="tabTxt" onClick={()=>{props.onTabChange(2); setCurrTab(2);}}>评价</span>
            <div className="chooseBar"></div>
        </div>
        <div className={currTab===3?'tabBoxSelecting tabBox':'tabBox'}>
            <span className="tabTxt" onClick={()=>{props.onTabChange(3); setCurrTab(3);}}>信息</span>
            <div className="chooseBar"></div>
        </div>
        </div>
        <div className="userDropDown">
        <UserDropdown supplier={props.supplier}></UserDropdown>
        </div>
        
    </div>
}