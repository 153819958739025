import { Avatar, Dropdown, Menu, Image } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import cookieStore from '@/store/cookie-store';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { getAvatarSrc } from '@/enums/avatar';
import { getUserTypes } from '@/enums/user-type';
import { Supplier } from '@/interface/supplier';

export default function UserDropdown(props: {supplier?: Supplier}) {

    const navigate = useNavigate();
    let user = cookieStore.getUser();

    const logOut = () => {
        cookieStore.clear();
        navigate('/code');
    }

    const menu =(
        <Menu className="setting-menu">
            <Menu.Item key="1" icon={<LogoutOutlined />} onClick={logOut}>
                退出登录
            </Menu.Item>
        </Menu>
    )

    return (
        <div className='user-dropdown-container'>
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <span className="user-dropdown">
                    <Avatar className="avatar" size="large" src={getAvatarSrc(user?.defaultHeadId)} />
                    <div className="name">
                        <span className="mobile">{cookieStore.getUser()?.mobile}</span>
                        <div style={{
                            color: "#1271ff", backgroundColor: "#d3e5ff", width: 60,
                            borderRadius: 5, alignItems: 'center', textAlign: 'center'
                        }}
                        >{getUserTypes(user, props.supplier)}</div>
                    </div>
                    <Image src='/assets/lower-triangle.png' style={{ color: '#191919' }} preview={false}></Image>
                </span>
            </Dropdown>
        </div >
    )
}