import { Alert, Button, Form, Input, message, Select } from "antd";
import { useState } from "react";
import './forget.less'
import { LockOutlined } from '@ant-design/icons'
import { passwordForget } from "../../api/user";
import { sendCode } from "../../api/user";
import CryptoJS from 'crypto-js'
import { useNavigate } from "react-router-dom/dist";
import React from "react";

export default function ForgetPwdPage(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    let [timeCount, setTimeCount] = useState(0);
    let [saveLoading, setSaveLoading] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [sendCodeLoading, setSendCodeLoading] = useState(false);

    let timer$;
    const onSendCodeMsg = () => {
        setSendCodeLoading(true);
        form.validateFields(['mobile']).then(res => {
            let mobile: string = form.getFieldValue('mobile');
            return sendCode(mobile);
        }).then(res => {
            if (res.status === 1020) {
                message.warn('验证码仍然有效，请勿重复发送')
                return;
            }
            setTimeCount(60);
            timer$ = setInterval(() => {
                setTimeCount((pre) => {
                    let count = pre - 1;
                    if (count <= 0) {
                        clearInterval(timer$);
                    }
                    return count;
                });
            }, 1000)



        }).catch(err => {
            console.log(err);
            return;
        }).finally(() => {
            setSendCodeLoading(false)
        })
    }

    const onSubmit = (values) => {
        setSaveLoading(true)
        let data = {
            ...values,
        }
        passwordForget(data).then(res => {
            if (res.status === 200) {
                message.success('密码修改成功！');
                navigate('/login')
            }
            else {
                setErrorMessage(res?.message);
                return;
            }
        }).catch(err => {
            message.error('密码修改失败');

        }).finally(() => {
            setSaveLoading(false)
        })
    }

    const onSend = () => {
        return <Alert
            style={{ marginBottom: '10px' }}
            message={"请等60秒后重新发送"}
            type="error"
            closable />
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle initialValue="86">
            <Select style={{ width: 80 }}>
                <Select.Option value="86">+86</Select.Option>
            </Select>
        </Form.Item>
    );

    const codeSufix = (
        <Button
            type="link"
            size="small"
            loading={sendCodeLoading}
            onClick={timeCount == 0 ? onSendCodeMsg : onSend}
            style={{ minWidth: '80px' }}
        >
            {timeCount > 0 ? `${timeCount}s` : '获取验证码'}
        </Button>
    )


    return (
        <div className="forget-pwd-container">
            <h3 className="title">密码找回</h3>
            <Form autoComplete="off" form={form} onFinish={onSubmit}>
                {!!errorMessage && (
                    <Alert
                        style={{ marginBottom: '10px' }}
                        message={errorMessage}
                        type="error"
                        closable
                        onClose={() => setErrorMessage('')} />
                )}
                <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: '手机号不能为空' }, { len: 11, message: '手机号格式不正确' }]}
                >
                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder="手机号" />
                </Form.Item>
                <Form.Item name="code" rules={[{ required: true, len: 6, message: '验证码格式不正确' }]}>
                    <Input style={{ width: '100%' }} placeholder="验证码" addonAfter={codeSufix} />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }, { min: 6, message: '密码不能小于6位' }]}>
                    <Input.Password placeholder="密码" prefix={<LockOutlined />} />
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    rules={[
                        {
                            required: true,
                            message: '确认密码不能为空',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次密码不一致');
                            },
                        }),
                    ]}
                    dependencies={['password']}
                    hasFeedback>
                    <Input.Password placeholder="再输一次密码" prefix={<LockOutlined />} />
                </Form.Item>
                <div className="submit-group">
                    <Button type="primary" htmlType="submit" className="submit-button" loading={saveLoading}>
                        提交
                    </Button>
                    <Button type="link" onClick={() => navigate('/login')}>
                        使用已有账户登录
                    </Button>
                </div>
            </Form>
        </div>
    )
}