import { addPaint, deletePaint, getPaints, updatePaint } from "@/api/paint";
import { Paint } from "@/interface/paint";
import cookieStore from "@/store/cookie-store";
import { Button, Checkbox, Col, Divider, Form, Image, Input, InputNumber,Popover, Popconfirm, Row, Space, Table, Upload, message } from "antd";
import { useContext, useEffect, useState } from "react";
import './goods-paint.less'
import './goods-topline.less'
import { PAINT_TYPE, generatePaintTypeString } from "@/enums/paint-type";
import { ContextMenuFC, DragModal, FileUploadParam, UploadFileLocale } from "tncet-common";
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { UploadFileContext } from "@/App";
import { resolve } from "path";
import { getFileByMd5, getFileDownloadUrl, getPresignedPutObjectUrl } from "@/api/file";
import { getSupplier } from "@/api/supplier";
import { Supplier } from "@/interface/supplier";

interface Iprops {
    supplierId: number;
    onTabChange: (number)=>any
}

export default function GoodsPaint(props: Iprops) {
    const [paints, setPatints] = useState<Paint[]>();
    const user = cookieStore.getUser();
    const [contactId, setContactId] = useState<number>();

    const [selectedPaint, setSelectedPaint] = useState<Paint>(); // 选中的油漆

    const [dmVis, setDmVis] = useState<boolean>(false);
    const [dmMode, setDmMode] = useState<string>("add"); // 用于记录弹窗是用于新增还是修改

    const [cmTop, setCmTop] = useState<number>();
    const [cmLeft, setCmLeft] = useState<number>();
    const [cmVis, setCmVis] = useState<boolean>(false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const uploadFileContext = useContext(UploadFileContext);
    const [uploadFileList, setUploadFileList] = useState<any>([]);
    const [uploadFile, setUplaodFile] = useState<FileUploadParam>();
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);

    const [supplier, setSupplier] = useState<Supplier>();

    const [previewImage,setPreviewImage] = useState<boolean>(false);


    useEffect(() => {
        if (!!props.supplierId) {
            generateData(props.supplierId);
        }
    }, [props])

    const generateData = (supplierId: number) => {
        getPaints({ supplierId: supplierId }).then(res => {
            if (res.status === 200) {
                let paints: Paint[] = res.data || [];
                setPatints(paints);
            }
        })
        getSupplier(props.supplierId).then(res => {
            if (res.status === 200) {
                setSupplier(res.data);
                // console.log(res.data)
                // console.log(user)

                setcanRelease(user.type===0 || user.id === res.data.userId)

                setContactId(res.data.userId);
            }
        })
    }

    const uploadCallbackFn = (uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
            getFileDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                setUplaodFile(uploadFile);
                setSelectedPaint({
                    ...selectedPaint,
                    md5: uploadFile?.md5,
                    suffix: uploadFile?.suffix,
                    url: res.data,
                })
                resolve(true);
            })
        })
    }

    useEffect(() => {
        if (uploadFileList.length === 0) return;
        let files: UploadFileLocale[] = uploadFileList.map(item => {
            return {
                file: item,
                callbackFn: uploadCallbackFn
            }
        })
        uploadFileContext.startUpload(files); //上传的关键
        setUploadFileList([]);
    }, [uploadFileList])

    const beforeUpload = (file, fileList) => {
        setCmVis(false);
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        let isImage = file && acceptedImageTypes.includes(file.type);
        if (!isImage) {
            message.warning("只能上传图片！")
        } else {
            if (uploadFileList.length === 0) {
                setUploadFileList([...fileList]);
            }
        }
        return false;
    }

    const onRemoveImage = () => {
        let tmp = selectedPaint;
        tmp.md5 = null;
        tmp.suffix = null;
        tmp.url = null;
        setSelectedPaint({ ...tmp });
        setUplaodFile(null);
        setUploadFileList([]);
        setIsDeleteImage(true);
    }

    const onOpenDm = (ope: string, row?: Paint) => {
        setSelectedPaint({ ...row });
        if (ope === "add") {
            setDmMode("add");
            form.resetFields();
        } else if (ope === "edit") {
            setDmMode("edit");
            form.setFieldsValue(
                {
                    ...row,
                    types: row.types
                }
            );

        }
        setDmVis(true);
    }

    const onImageRightClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let clientX = e.clientX;
        let clientY = e.clientY;
        setCmLeft(clientX);
        setCmTop(clientY);
        setCmVis(true);
    }

    const onInsertPaint = (values) => {
        let newPaint: Paint = {
            ...values,
            types: values.types,
            deleteFile: isDeleteImage,
        }

        if (!!uploadFile) {
            newPaint['file'] = {
                fileMD5: uploadFile.md5,
                suffix: uploadFile.suffix,
                filePath: uploadFile.filePath,
                fileSize: uploadFile.fileSize,
            };
        }

        setLoading(true);
        if (dmMode === "add") {
            addPaint(props.supplierId, newPaint).then(res => {
                if (res.status === 200) {
                    setDmVis(false);
                    setLoading(false);
                    generateData(props.supplierId);
                } else {
                    message.warning("添加失败");
                    setLoading(false);
                }
            }).catch(e => {
                message.warning("添加失败");
                setLoading(false);
            })
        } else if (dmMode === "edit") {
            updatePaint(selectedPaint.id, newPaint).then(res => {
                if (res.status === 200) {
                    setDmVis(false);
                    setLoading(false);
                    generateData(props.supplierId);
                } else {
                    message.warning("修改失败");
                    setLoading(false);
                }
            }).catch(e => {
                message.warning("修改失败");
                setLoading(false);
            })
        }

    }

    const handleDelete = (paint: Paint) => {
        setDeleteLoading(true);
        deletePaint(paint.id).then(res => {
            if (res.status === 200) {
                let tmp = paints.filter(item => item.id !== paint.id);
                setPatints(tmp);
            } else {
                message.warning("删除失败");
            }
            setDeleteLoading(false);
        }).catch(e => {
            message.warning("删除失败");
            setDeleteLoading(false);
        })
    }


    // 新ui样式
    const [itemType, setitemType] = useState<number>(2)
    const [canRelease, setcanRelease] = useState<boolean>(false)

    function GoodsTopLine(){
        return(
        <div className="goods-topLine">
            <div className="titleBox">

                <span className="title">
                    {supplier?.name}
                </span>
            </div>
            <div className="filterBox">
                <span className="filterTxt">商品类型：</span>
                <div className={itemType===1?"filterItem filterItemChosen":"filterItem"}
                onClick={()=>{setitemType(1);props.onTabChange(1)}}>
                    <span className="filterTxt">钢材</span>
                </div>
                <div className={itemType===2?"filterItem filterItemChosen":"filterItem"}
                onClick={()=>{setitemType(2);props.onTabChange(2)}}>
                    <span className="filterTxt">油漆</span>
                </div>
                <div className={itemType===3?"filterItem filterItemChosen":"filterItem"}
                 onClick={()=>{setitemType(3);props.onTabChange(3)}}>
                    <span className="filterTxt">防火涂料</span>
                </div>
            </div>
            <div className="releaseBox">
                {canRelease&&<Button className="release" onClick={() => onOpenDm("add")}>
                    <span>上架</span>
                </Button>}
            </div>
        </div>
        )
    }

    function PaintEntry(props: {data: Paint}){
        const [PopoverOn, setPopoverOn] = useState<boolean>(false);
        const [imageUrl, setImageUrl] = useState<string>();
        useEffect(()=>{
            if (props.data.md5){
                getFileDownloadUrl(props.data.md5+props.data.suffix).then((res)=>{
                    if (res.status===200){
                        setImageUrl(res.data)
                    }
                })
            }
        },[])
        return (
            <div className="paintEntry">
                <div className="imageBox">
                    <Image width={81} height={75} className="image" src={imageUrl||"/assets/paint.png"}/>
                </div>
                <div className="rightBox">
                    <div className="infoLine">
                        <span>
                            {props.data.name}
                        </span>
                        {canRelease&&<Popover
                         placement="right"
                          content={<div>
                            <div className="xxx" style={{width:'100%',marginTop: '-10px'}}
                            onClick={() => onOpenDm("edit", props.data)}>
                                <Button style={{border: 0}}>编辑</Button>
                                </div>
                            <div style={{marginBottom: '-10px'}}
                            onClick={()=>{handleDelete(props.data)}}>
                                <Button style={{border: 0, color: 'red'}}>删除</Button>
                            </div>
                          </div>}
                        //   title="Title"
                          trigger="click"
                          open={PopoverOn}
                          onOpenChange={(e)=>{setPopoverOn(e)}}
                          
                        >
                        <EllipsisOutlined style={{fontSize: "24px"}} onClick={()=>{}}/>
                        </Popover>}
                    </div>
                    <div className="priceLine">
                        <span>
                        {props.data.price&&props.data.price+'元/桶'||'价格面议'}&nbsp;&nbsp;({props.data.specification}L)
                        </span>

                    </div>
                    <div className="infoLine">
                        <span>
                        {Object.keys(props.data.types).map((key, index, array) => (
                                PAINT_TYPE[props.data.types[index]]+ (index!==array.length-1?" | ":"")
                            ))}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className="paint">
            <GoodsTopLine/>
            <div className="paint-page">
                {
                    paints?.map(data=>(
                        <div key={data?.id}>
                        <PaintEntry data={data}/>
                        </div>
                    ))
                }
            </div>
        </div>
           {canRelease&&<DragModal
                visible={dmVis}
                width={800}
                onClose={() => setDmVis(false)}
                mask
                destroyOnClose
            >
                <div className="add-paint-dm-container">
                    <div className="title">
                        {dmMode === "add" ? "添加油漆" : "编辑油漆"}
                    </div>
                    <Form className="form" form={form} autoComplete="false" onFinish={onInsertPaint}>
                        <Row>
                            <Col span={22}>
                                <Form.Item label="名称" name="name" rules={[{ required: true, message: "名称不能为空" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="规格" name="specification" rules={[{ required: true, message: "名称不能为空" }]}>
                                    <InputNumber style={{ width: '100%' }} addonAfter="L/桶" />
                                </Form.Item>
                                <Form.Item label="类型" name="types">
                                    <Checkbox.Group>
                                        {Object.keys(PAINT_TYPE).map((key, index) => (
                                            <Checkbox key={index} value={parseInt(key)} style={{ marginLeft: 5 }}>
                                                {PAINT_TYPE[parseInt(key)]}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>
                                <Form.Item label="特性" name="features">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="价格" name="price">
                                    <InputNumber style={{ width: '100%' }} addonAfter="元" precision={2} />
                                </Form.Item>
                                <Form.Item label="图片" >
                                    <Upload beforeUpload={beforeUpload} listType="picture-card" fileList={!!selectedPaint?.url ? [{ uid: '1', url: selectedPaint.url, name: "img.png", status: "done" }] : []} onRemove={onRemoveImage} onPreview={() => setPreviewImage(true)}>
                                        {!selectedPaint?.url && <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>上传图片</div>
                                        </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Divider /> */}
                        <div className="footer">
                            <Button onClick={() => setDmVis(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" loading={loading} style={{background: '#1271FF'}}>确认</Button>
                            
                        </div>
                    </Form>
                </div>
            </DragModal>}
            <DragModal
                title="预览"
                width={600}
                visible={previewImage}
                onClose={() => setPreviewImage(false)}
                mask
            >
                <div>
                    <img alt="example" style={{ width: '100%' }} src={selectedPaint?.url} />
                </div>
            </DragModal>
        </>

    )
}