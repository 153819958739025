import { Supplier } from "@/interface/supplier";
import { User } from "@/interface/user";

export const UserType = {
    ADMIN: 0,
    USER: 1,
}

export function getUserTypes(user: User, supplier?: Supplier) {
    if(user.type === UserType.ADMIN) {
        return "管理员"
    } else {
        if (!supplier){
            // 目前，cookie的user里没有supplier
            if(!!user.supplier) {
                return "商家"
            } else {
                return "用户"
            }
        }
        else{
            if (supplier.userId===user.id){
                return "商家"
            }
            return "用户"
        }
       
    }
}