import { getSuppliers } from "@/api/supplier"
import { Supplier } from "@/interface/supplier";
import { Button, Checkbox, Divider, Form, Image, Input, Table, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import { pageSizeOption } from "@/enums/page-size-option";
import { transStatusColor, SupplierTagList, transSupplierStatus } from "@/enums/supplier";
import { SupplierTag } from "@/enums/supplier";
import './main.less';
import { useNavigate } from "react-router-dom";
import { PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons'
import {
    SearchOutlined
} from '@ant-design/icons';
import UserDropdown from "@/component/user-dropdown";
import cookieStore from "@/store/cookie-store";
import { User } from "@/interface/user";
import { UserType } from "@/enums/user-type";


export default function SupplierList() {
    const [SupList, setSupList] = useState<Supplier[]>([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(30); // Table每页展示的数量
    const [currentPage, setCurrentPage] = useState<number>(1); // Table当前页
    const [inputName, setInputName] = useState<string>(null);
    const [number, setNumber] = useState<number>(1); // 待审核数量
    let [tagTypes, setTagTypes] = useState<number[]>([]);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth - 277 - 277);
    let user: User = cookieStore.getUser();

    useEffect(() => {
        getSuppliers(tagTypes, inputName).then(res => {
            let supplier: Supplier[] = res.data || [];
            // 不同角色对应不同的状态顺序
            let type = user.type
            let resultList: Supplier[] = []
            let supplierCerified = supplier.filter(t => t.status === SupplierTag.CERTIFIED);
            let supplierReview = supplier.filter(t => t.status === SupplierTag.IN_REVIEW);
            let supplierModifying = supplier.filter(t => t.status === SupplierTag.MODIFYING);
            let supplierNotCerified = supplier.filter(t => t.status === SupplierTag.NOT_CERTIFIED);
            if (type === UserType.ADMIN) {
                // 管理员
                resultList.push(...supplierReview)
                resultList.push(...supplierModifying)
                resultList.push(...supplierCerified)
                resultList.push(...supplierNotCerified)
            } else {
                if (!!user.supplier) {
                    // 商家
                    resultList.push(...supplierCerified)
                    resultList.push(...supplierModifying)
                    resultList.push(...supplierReview)
                    resultList.push(...supplierNotCerified)
                    let mine = resultList.find(item => item.userId == user.id);
                    let mineIndex = resultList.findIndex(item => item.userId == user.id);
                    if (!!mine) {
                        resultList.splice(mineIndex, 1);
                        resultList.unshift(mine);
                    }
                } else {
                    // 用户
                    resultList.push(...supplierCerified)
                    resultList.push(...supplierModifying)
                    resultList.push(...supplierReview)
                    resultList.push(...supplierNotCerified)
                }
            }

            setSupList(resultList);
            if (tagTypes.length == 0 && !inputName) {
                // 所有商家中认证审核中的数量
                let certSupList = supplier.filter(list => {
                    return list.status === SupplierTag.IN_REVIEW;
                })
                setNumber(certSupList.length)
            }
        }).catch(err => {
            navigate('/code');
            console.log(err);
        })
    }, [tagTypes, inputName])

    const onChangeType = (num: number) => {
        if (!tagTypes.includes(num)) {
            setTagTypes(tagTypes.concat(num));
        }
        else {
            setTagTypes(tagTypes =>
                tagTypes.filter(tag => {
                    return tag !== num;
                }),
            );
        }
    }

    useEffect(() => {
        const handleResize = () => {
            let innerWidth = window.innerWidth - 277 - 277
            console.log('innerWidth', innerWidth)
            setScreenWidth(innerWidth);
        };
        window.addEventListener('resize', handleResize); return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const searchInput = () => {
        let name = form.getFieldValue("name") == '' ? null : form.getFieldValue("name");
        setInputName(name);
    }

    return (
        <div className="main-container">
            <div className="header">
                <div className="header-image">
                    <Image src="/assets/logo.png" preview={false}></Image>
                </div>
                <div className="header-title">
                    <Image src="/assets/快截.png" preview={false} height={32}></Image>
                </div>


                <div className="header-input">
                    <Form form={form}>
                        <Form.Item name="name" style={{ width: 354, alignItems: 'center' }}>
                            <Input
                                style={{ height: 40, alignItems: 'center' }}
                                suffix={<SearchOutlined style={{ color: '#a5a9ab' }} />}
                                onBlur={(e) => setInputName(e.target.value)}
                                onPressEnter={searchInput}
                                placeholder="请输入公司名称"
                            />
                        </Form.Item>
                    </Form>
                </div>
                <p style={{ flex: 1 }}></p>
                {SupplierTagList?.map(item => {
                    
                    return(
                     <Checkbox
                        defaultChecked={false}
                        key={item?.label}
                        //value={item.value}
                        className="checkboxs"
                        onClick={(e) => { onChangeType(item.value) }}
                    >

                        {item.label}
                    </Checkbox>
                   
                )}
            )}

                <div className="header-right-area">
                    <div className="user-drop-down">
                        <UserDropdown ></UserDropdown>
                    </div>
                </div>

            </div>
            <div className="supplier-list" >
                <div className="list-content" style={{ width: screenWidth }}>
                    <Table
                        dataSource={SupList}
                        rowKey="id"
                        onRow={row => {
                            return {
                                onClick: () => {
                                    navigate("/suppliers/" + row.id);
                                }
                            }
                        }}
                        showHeader={true}
                        rowClassName="table-row"
                        pagination={{
                            pageSize: pageSize,
                            showSizeChanger: true,
                            pageSizeOptions: pageSizeOption,
                            // hideOnSinglePage: true,
                            onShowSizeChange(current, size) {
                                setPageSize(size);
                            },
                            total: SupList?.length,
                            showTotal: ((total) => {
                                return `共${total}条`
                            }),
                            onChange(page) {
                                setCurrentPage(page);
                            },
                            className: 'custom-pagination',
                        }}
                        sticky
                        // scroll={{ y: `calc(100vh - ${SupList.length > pageSize ? 166 : 120}px)` }}
                        scroll={{ y: `calc(100vh - 166px)` }}
                    >
                        <Table.Column className="content-company-name" title="公司名称" width="30%" ellipsis dataIndex="name" render={(text, item: Supplier) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    {text}
                                    {item.userId === user.id && !!user.supplier && (
                                        <div style={{
                                            width: 45, height: 18, borderRadius: 10,
                                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                                            backgroundColor: '#c9f6c2', fontSize: 13,
                                            color: '#149000', fontWeight: 'bold', justifyContent: 'center',
                                            position: 'absolute', marginRight: 5, right: 0
                                        }}>我的</div>
                                    )}
                                </div>
                            )
                        }} />
                        <Table.Column className="content-status" title="认证状态" width="17%" ellipsis dataIndex="status" render={type => type && (<div style={{ color: transStatusColor(type) }}>{transSupplierStatus(type)}</div>)} />
                        <Table.Column className="content-company-address" title="公司地址" ellipsis={true} width="27%" dataIndex="address"
                            render={(add: string) => {
                                return (
                                    <div title={add} style={{ textAlign: 'left', fontSize: 16 }}>
                                        <EnvironmentOutlined />
                                        &nbsp;&nbsp;
                                        {add}
                                    </div>
                                )
                            }} />
                        <Table.Column className="content-person-phone" title="联系电话" width="26%" render={(sup: Supplier) => {
                            return (
                                <div>
                                    {(!!sup.contactMobile || !!sup.contactName) && <PhoneOutlined />}
                                    &nbsp;&nbsp;
                                    {sup.contactMobile || ''}{sup.contactName ? (`(${sup.contactName})`) : ''}
                                </div>
                            )
                        }} />

                    </Table>
                </div>
            </div>
        </div>
    )
}