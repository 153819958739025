import { Fragment, useEffect, useRef, useState } from 'react'
import './index.less'
import { sendUserData } from '@/api/app-download';
import { Alert, Button, Input, message } from 'antd';
import CryptoJS from 'crypto-js';
import classNames from 'classnames';
import appStore, { REMEMBER_TELEPHONE } from '@/store/app-store';
import validator from 'validator';
import { checkVarification, sendCode } from '@/api/user';
import {
    DownOutlined,
} from '@ant-design/icons'
import DeviceDetector from 'device-detector-js';

export default function DownloadApp() {
    const [mobile, setMobile] = useState<string>('');
    const [mobileCode, setMobileCode] = useState<string>('+86');
    const [validPhone, setValidPhone] = useState<boolean>(false);
    const [sendingSmsCode, setSendingSmsCode] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>('');
    const [codes, setCodes] = useState<string[]>(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [timeCount, setTimeCount] = useState<number>(0);
    const [canDownload, setCanDownload] = useState<boolean>(false);

    useEffect(() => {
        let mobile = JSON.parse(appStore.get(REMEMBER_TELEPHONE)) || "";
        setMobile(mobile);
    }, [])

    useEffect(() => {
        // console.log(validatePhone("+86", "18658832701"))
        // 校验短信验证是否可以下一步
        // if (loginType !== 'MOBILE') return;
        const isValidPhone = validatePhone(mobileCode, mobile);
        setValidPhone(isValidPhone);
        // setValiPass(isValidPhone && agreeTerms);
        // console.log(isValidPhone, agreeTerms)
    }, [mobileCode, mobile])

    const validatePhone = (mobileCode: string, mobile: string) => {
        const isValid = validator.isMobilePhone(mobileCode + mobile, "any", {
            strictMode: true,

        })
        return isValid;
    }

    const onMobileNextClick = () => {
        sendAuthCode();
    }

    const sendAuthCode = () => {
        setSendingSmsCode(true);
        setErrMsg('');
        sendCode(mobile).then(res => {
            if (res.status !== 200) {
                setErrMsg(res.message);
                return;
            }
            inputRefs.current[0]?.focus();
            countDown();
        }).catch(err => {
            setErrMsg('发生异常');
            console.error(err);
        }).finally(() => {
            setSendingSmsCode(false);
        })
    }

    useEffect(() => {
        // countDown();
    }, [])

    let timer$: any;
    const countDown = () => {
        setTimeCount(60);
        timer$ = setInterval(() => {
            setTimeCount((pre) => {
                let count = pre - 1;
                if (count <= 0) {
                    clearInterval(timer$);
                }
                return count;
            });
        }, 1000)
    }

    useEffect(() => {
        const authCode = codes.join('');
        if (authCode.length == 6) {
            // 验证
            checkVarification(mobile, authCode).then(res => {
                if (res.data) {
                    setCanDownload(true);
                    // 销售员id
                    const urlParams = new URLSearchParams(window.location.search);
                    let sellerId = urlParams.get('referral');
                    if (!!sellerId) {
                        sellerId = CryptoJS.enc.Base64.parse(sellerId).toString(CryptoJS.enc.Utf8);
                        sendUserDataToServer(mobile, Number(sellerId))
                    }
                } else {
                    setErrMsg("验证码错误");
                    setCodes(['', '', '', '', '', ''])
                    inputRefs.current[0]?.focus();
                }
            }).catch((err) => {
                console.log(err)
                setErrMsg("发生异常");
            })
        }
    }, [codes])

    const sendUserDataToServer = (mobile: string, sellerId: number) => {
        let data = {
            mobile: mobile,
            sellerId: sellerId,
        }
        sendUserData(data).then(res => {

        }).finally(() => {

        })
    }

    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            return "Android";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            return "iOS";
        } else {
            return "Unknown";
        }
    }

    const download = () => {
        if (!canDownload) {
            setErrMsg("请先完成手机号验证");
            return;
        }
        let os = getMobileOperatingSystem();
        // 跳转链接
        let appStoreLink = '';
        // apk地址
        const apkUrl = "/api/static/app-release.apk"
        if (os == "Android") {
            // 设备品牌
            const userAgent = navigator.userAgent;
            const detector = new DeviceDetector();
            const result = detector.parse(userAgent);
            const brand = result.device.brand;
            switch (brand) {
                case '华为':
                case '荣耀':
                case 'HUAWEI':
                case 'Huawei':
                case 'HONOR':
                case 'Honor':
                    // 华为应用市场
                    appStoreLink = "https://appgallery.huawei.com/app/C108423749";
                    break;
                case '小米':
                case 'Xiaomi':
                case 'XIAOMI':
                case 'xiaomi':
                case 'MiuiBrowser':
                    // 小米应用市场
                    appStoreLink = "http://app.xiaomi.com/detail/1557186";
                    break;
                case 'OPPO':
                case 'Oppo':
                case 'oppo':
                case 'OPR':
                    appStoreLink = '';
                    break;
                case 'vivo':
                case 'VIVO':
                case 'Vivo':
                case 'VivoBrowser':
                    appStoreLink = '';
                    break;
                default:
                    appStoreLink = '';
            }
            if (appStoreLink.length == 0) {
                // 下载apk
                const link = document.createElement('a');
                link.href = apkUrl;
                link.download = 'kuaijie_2.1.1.apk'; // 设置下载文件的名称
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.location.href = appStoreLink;
            }
        } else if (os == "iOS") {
            appStoreLink = "https://apps.apple.com/cn/app/%E5%BF%AB%E6%88%AA-%E5%BF%AB%E9%80%9F%E6%88%AA%E9%9D%A2%E6%9F%A5%E8%AF%A2%E5%88%A9%E5%99%A8/id1332966906";
            window.location.href = appStoreLink;
        } else {
            // 下载apk
            const link = document.createElement('a');
            link.href = apkUrl;
            link.download = 'kuaijie_2.1.1.apk'; // 设置下载文件的名称
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // message.info("请至应用市场下载");
        }
    }

    return (
        <div className="download-page-container">
            <div className="title">快截</div>
            <a href="http://www.tncet.com" target="_blank" className="compony">同恩（上海）工程技术有限公司</a>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <img src="/app-logo.png" width="150px"></img>
            </div>

            <div >请输入手机号安装</div>
            <div>
                <div className={classNames({
                    'mobile-input': true,
                    'mobile-input-error': !validPhone
                })} style={{
                    'marginBottom': '4px',
                    'width': '100%'
                }}>
                    <div className='mobile-input-left'>
                        <Input
                            className='mobile-input-code'
                            readOnly
                            bordered={false}
                            autoComplete='off'
                            autoCapitalize='off'
                            spellCheck="false"
                            value={mobileCode}
                            disabled={sendingSmsCode}
                        />
                        <DownOutlined className='mobile-input-dropdown-icon' />

                    </div>
                    <div className='mobile-input-right'>
                        <Input
                            className='mobile-input-phone'
                            bordered={false}
                            placeholder='请输入您的手机号'
                            spellCheck="false"
                            autoComplete='off'
                            autoCapitalize='off'
                            autoCorrect='off'
                            value={mobile}
                            onKeyDown={e => {
                                if (!("0123456789".includes(e.key)
                                    || "Tab" == e.key
                                    || "Enter" == e.key
                                    || "Backspace" == e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            disabled={sendingSmsCode}
                            autoFocus
                            onPressEnter={() => {
                                onMobileNextClick();
                            }}
                            onChange={e => {
                                setMobile(e.target.value)
                                setErrMsg('');
                            }} />
                    </div>
                </div>
                {timeCount > 0 && (
                    <div className='code-box-count'>
                        {`${timeCount} 秒后可重新获取验证码`}
                    </div>
                )}
                {timeCount <= 0 && (
                    <div className='code-resend'>
                        <Button type='link' className='code-resend-btn' style={{
                            padding: '0',
                            marginBottom: '12px'
                        }} onClick={sendAuthCode} loading={sendingSmsCode}>获取验证码</Button>
                    </div>
                )}
                <div className='code-box'>
                    {codes.map((code, index) => (
                        <Fragment key={index}>
                            <div className='code-box-input-wrap' >
                                <Input
                                    ref={(ref) => inputRefs.current[index] = ref}
                                    value={code}
                                    onKeyDown={e => {
                                        if (e.key == 'Backspace') {
                                            const newCodes = [...codes];
                                            if (codes[index].length > 0) {
                                                newCodes[index] = '';
                                                setCodes(newCodes);
                                                return;
                                            }
                                            newCodes[index - 1] = '';
                                            setCodes(newCodes);
                                            if (index > 0) {
                                                inputRefs.current[index - 1].focus();
                                            }
                                        }
                                    }}
                                    onChange={e => {
                                        const value = e.target.value;
                                        if (value.length > 1) return;
                                        if (!'0123456789'.includes(value)) return;
                                        setErrMsg('');
                                        const newCodes = [...codes];
                                        newCodes[index] = value;
                                        setCodes(newCodes);
                                        if (value && index < 5) {
                                            inputRefs.current[index + 1].focus();
                                        }
                                    }}
                                    className={classNames({
                                        'code-box-input': true,
                                        'code-box-input-error': errMsg.length > 0
                                    })}
                                    type='text'
                                    spellCheck="false"
                                    autoCapitalize='off'
                                    autoComplete='off'
                                    autoCorrect='off'
                                    maxLength={1}
                                />
                            </div>
                            {index == 2 && (
                                <div className='code-box-input-seg-line' key="-1"></div>
                            )}
                        </Fragment>
                    ))}

                </div>
                {errMsg.length > 0 && (
                    <div style={{
                        marginBottom: '16px'
                    }}>
                        <Alert message={errMsg} type='error' />
                    </div>
                )}
            </div>
            <Button className="btn" onClick={download} style={{ backgroundColor: canDownload ? '#01875f' : '#83878b' }}>安装</Button>
        </div>
    )
}