export const PAINT_TYPE: { [key: number]: string } = {
    1: "醇酸底涂料",
    2: "环氧铁红底涂料",
    3: "聚氯乙烯萤丹底涂料",
    4: "富锌底涂料",
    5: "氯化橡胶底涂料",
    6: "高氯化聚乙烯底涂料",
    7: "氯磺化聚乙烯底涂料",
    8: "环氧云铁中间涂料",
    9: "醇酸面涂料",
    10: "氯化橡胶面涂料",
    11: "高氯化聚乙烯面涂料",
    12: "氯璜化聚乙烯面涂料",
    13: "环氧面涂料",
    14: "聚氨酯面涂料",
    15: "丙烯酸环氧面涂料",
    16: "丙烯酸聚氨酯面涂料",
    17: "乙烯基酯玻璃麟片面涂料",
    18: "聚氯乙烯萤丹面涂料",
    19: "聚氯乙烯含氟萤丹面涂料"
}

export function generatePaintTypeString(types: number[]) {
    let typeString = "";
    types.map((type,idx) => {
        if (idx === 0) {
            typeString = PAINT_TYPE[type];
        } else {
            typeString = typeString + "+" + PAINT_TYPE[type];
        }
    })

    return typeString;
}