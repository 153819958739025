
export function getAvatarSrc (id:number){
    switch(id){
        case 1: return "../assets/avatar/asiaF/asiaF@2x.png";
        case 2: return "../assets/avatar/euroM/euroM@2x.png";
        case 3: return "../assets/avatar/euroF/euroF@2x.png";
        case 4: return "../assets/avatar/africaM/africaM@2x.png";
        case 5: return "../assets/avatar/africaF/africaF@2x.png";
        default:
        case 0: return "../assets/avatar/asiaM/asiaM@2x.png";

    }
}