export const SupplierTag = {
    NOT_CERTIFIED: 1, // 未认证
    IN_REVIEW: 2, // 认证审核中
    MODIFYING: 3, // 认证修改中
    CERTIFIED: 4, // 已认证
}
export function transSupplierStatus(type: number) {
    switch (type) {
        case SupplierTag.NOT_CERTIFIED:
            return "未认证";
        case SupplierTag.IN_REVIEW:
            return "认证审核中";
        case SupplierTag.MODIFYING:
            return "认证修改中";
        case SupplierTag.CERTIFIED:
            return "已认证";
    }
}
export const SupplierTagList = [
    { label: '未认证', value: 1 },
    { label: '已认证', value: 4 },
    { label: '认证审核中', value: 2 },
    { label: '认证修改中', value: 3 },

]
export function transStatusColor(type: number) {
    switch (type) {
        case SupplierTag.NOT_CERTIFIED:
            return "#ff0000";
        case SupplierTag.IN_REVIEW:
            return "#1271ff";
        case SupplierTag.MODIFYING:
            return "#1271ff";
        case SupplierTag.CERTIFIED:
            return "#008000";
    }
}