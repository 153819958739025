import { Steel } from "@/interface/steel";
import axios, { AxiosResponse } from "axios";

export function getSteels(id:number,params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/steels`, {
        params: {
            supplierId:id,
            ...params,
        },
    })
}

export function addSteel(id: number, data: Steel, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/suppliers/${id}/steels`, data, {
        params: params
    })
}

export function deleteSteel(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/steels/${id}`, {
        params: params
    })
}

export function updateSteel(id: number, data: Steel, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/steels/${id}`, data, {
        params: params
    })
}

export function getSteelDetail(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/steels/${id}`, {
        params: params
    })
}
