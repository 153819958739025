import axios from "axios";
import { AxiosResponse } from "axios";

export function getFileByMd5(md5: string){
    return axios.get(`/files/md5/${md5}`)
}

export function getPresignedPutObjectUrl(objectName: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/objects/upload/url`, {
        params: {
            objectName: `${objectName}`,
            ...params,
        },
    })
}

export function getFileDownloadUrl(objectName: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/objects/url`, {
        params: {
            objectName: `${objectName}`,
            ...params,
        },
    })
}