import { Coating } from "@/interface/coating";
import axios, { AxiosResponse } from "axios";

export function addCoating(supplierId: number,data: Coating, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`/suppliers/${supplierId}/coatings`, data, {
        params: params
    })
}

export function deleteCoating(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/coatings/${id}`, {
        params: params
    })
}

export function updateCoating(id: number, data: Coating, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/coatings/${id}`, data, {
        params: params
    })
}

export function getCoatingDetail(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/coatings/${id}`, {
        params: params
    })
}

export function getCoatings(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/coatings`, {
        params: params
    })
}