import { Alert, Button, Checkbox, Form, Input, message, Select } from "antd";
import './code.less'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import appStore, { REMEMBER_PASSWORD, REMEMBER_TELEPHONE, REMEMBER_TAG } from "../../store/app-store";
import CryptoJS from 'crypto-js';
import { sendCode } from "../../api/user";
import { telLogin } from "../../api/user";
import { User } from "../../interface/user";
import cookieStore from "../../store/cookie-store";
import { useNavigate } from "react-router-dom/dist";
import React from "react";
import { number } from "echarts";
import { UserType } from "@/enums/user-type";


export const targetUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : `http://project.${document.domain.split('.').slice(-2).join('.')}`;

export default function Code(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    let [timeCount, setTimeCount] = useState(0);
    const location = useLocation();
    let [errorMessage, setErrorMessage] = useState('');
    let [loading, setLoading] = useState(false);
    let [sendCodeLoading, setSendCodeLoading] = useState(false);
    let timer$;
    useEffect(() => {
        // appStore.remove(REMEMBER_TELEPHONE);
        // appStore.remove(REMEMBER_PASSWORD);
        // appStore.remove(REMEMBER_TAG);
        // 从本地存储中获取自动保存的账户信息
        let mobile = JSON.parse(appStore.get(REMEMBER_TELEPHONE)) || "";
        let remember = JSON.parse(appStore.get(REMEMBER_TAG)) || "";
        form.setFieldsValue({
            mobile: mobile.toString(),
            remember: remember,
        });
    }, [])

    const onGetCode = () => {
        setSendCodeLoading(true);
        form.validateFields(['mobile']).then(res => {
            let mobile: string = form.getFieldValue('mobile');
            return sendCode(mobile);
        }).then(res => {
                if (res.status === 1020) {
                    message.warn('验证码仍然有效，请勿重复发送')
                    return;
                }
                setTimeCount(60);
                timer$ = setInterval(() => {
                    setTimeCount((pre) => {
                        let count = pre - 1;
                        if (count <= 0) {
                            clearInterval(timer$);
                        }
                        return count;
                    });
                }, 1000)
    
    
            }).catch(err => {
                console.log(err);
                return;
            }).finally(() => {
                setSendCodeLoading(false)
            })
        }
        
    const onSend = () => {
        return <Alert
            style={{ marginBottom: '10px' }}
            message={"请等60秒后重新发送"}
            type="error"
            closable />
    }

    const login = (values) => {
        //错误信息清空
        setErrorMessage('');
        setLoading(true);
        let data = {
            mobile: values.mobile,
            code: values.code,
            clientType: "web",
        };
        telLogin(data)
            .then(res => {
                // 登录失败
                if (res.status !== 200) {
                    setErrorMessage(res?.message);
                    return;
                }
                setErrorMessage("登录成功");
                let userDetail: User = res.data;
                // console.log(userDetail);

                // 向cookie中存user数据以判断是否为管理员
                cookieStore.setUser(userDetail)

                // appStore.set(AUTHORIZATION, userDetail.token);
                cookieStore.setToken(userDetail.token || '');
                cookieStore.setLoginId(userDetail.id);
                // appStore.set(USER_ID, userDetail.id);
                // userStore.setUserInfo(userDetail);
                if (values.remember) {
                    appStore.set(REMEMBER_TELEPHONE, values.mobile);
                    appStore.set(REMEMBER_TAG, values.remember);
                } else {
                    appStore.remove(REMEMBER_TELEPHONE);
                    //appStore.remove(REMEMBER_PASSWORD);
                    appStore.remove(REMEMBER_TAG);
                }
                // 根据角色跳转
                if(userDetail.type === UserType.ADMIN) {
                    navigate('/suppliers');
                } else {
                    if(!!userDetail.supplier) {
                        navigate('/suppliers/' + userDetail.supplier.id);
                    } else {
                        navigate('/suppliers');
                    }
                }
            }).catch(err => {
                console.log(err)
                setErrorMessage('登录失败');
            }).finally(() => {
                setLoading(false);
            })
    }
    const codeSufix = (
        <Button
            type="link"
            size="small"
            loading={sendCodeLoading}
            onClick={timeCount == 0 ? onGetCode : onSend}
            style={{ minWidth: '80px' }}
        >
            {timeCount > 0 ? `${timeCount}s` : '获取验证码'}
        </Button>
    )

    const prefixSelector = (
        <Form.Item name="prefix" noStyle initialValue="86">
            <Select style={{ width: 80 }}>
                <Select.Option value="86">+86</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <div className="login-container">
            <Form className="login-form" form={form} onFinish={login} autoComplete="off">
                {!!errorMessage && (
                    <Alert
                        style={{ marginBottom: '10px' }}
                        message={errorMessage}
                        type="error"
                        closable
                        onClose={() => setErrorMessage('')} />
                )}

                <Form.Item name="mobile" rules={[{ required: true, message: '电话号不能为空' }, { len: 11, message: '手机号格式不正确' }]}>
                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder="手机号" />
                </Form.Item>
                <Form.Item name="code" rules={[{ required: true, len: 6, message: '验证码格式不正确' }]}>
                    <Input style={{ width: '100%' }} placeholder="验证码" addonAfter={codeSufix} />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>
                    <NavLink className="login-form-password" to={"/login"}>
                        密码登录?
                    </NavLink>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                        登 录
                    </Button>

                </Form.Item>
            </Form>
        </div>
    )
}
