import { Tabs, Input, Button, List, Card } from "antd";
import './detail.less';
import { useParams } from 'react-router-dom';

import { useEffect, useState } from "react";

import DetailEvaluation from "./supplier-evaluation";
import SupplierInfo from "./supplier-info";

import { Image } from 'antd';
import { Rate } from 'antd';
import { Evaluation } from "@/interface/evalutaion";
import { getAvatarSrc } from "@/enums/avatar";
import cookieStore from "@/store/cookie-store";
import { getSupplier, inspectSupplier } from "@/api/supplier";

import GoodsDetail from "./goods-detail";
import SupplierDetailTopbar from "./supplier-detail-topbar";
import { Supplier } from "@/interface/supplier";
import GoodsPaint from "./goods-paint";

interface DetailProps{
    detailTab?: 1|2|3,
    goodsTab?: 1|2|3
}

export default function SupplierDetail(props: DetailProps){
    const [nowTab, setNowTab] = useState<number>(1);
    let id = Number.parseInt(useParams().id) 
    const [supplier, setSupplier] = useState<Supplier>();
    const [canEdit, setCanEdit] = useState<Boolean>(false);
    useEffect(()=>{
        getSupplier(id).then(res=>{
            setSupplier(res.data)
        })
    }, [])
    const tabItems = [
        {
            label: "商品",
            key: "1",
            children:
                <div className="goods-container">
                </div>

        },
        {
            label: "评价",
            key: "2",
            children:
                DetailEvaluation({ supplierId: id })

        },
        {
            label: "商家信息",
            key: "3",
            children:
                SupplierInfo({ supplierId: id })
        },
    ]
    // 点击导航栏不同标签后的回调函数
    function handleTabChange(num:number){
        if(num === 1){
            setCanEdit(true);
        }        setNowTab(num)

    }

    return (
        <div className="detail-container">
            <SupplierDetailTopbar supplier={supplier} onTabChange={handleTabChange} onTab={nowTab}/>
            {nowTab===1&&<GoodsDetail supplierId={id}/>}
            {nowTab===2&&<DetailEvaluation supplierId={id}/>}
            {nowTab===3&&<SupplierInfo supplierId={id}/>}
           {/* <div className="goods-container">
            {canEdit &&(<GoodsDetail supplierId={id} supplierName={supplier?.name} userId={supplier?.userId}></GoodsDetail>)}
           </div> */}
            
           
            
        </div>
    )
}