import axios, { StdResponse } from "../config/axios"
import { AxiosResponse } from "axios"
import qs from 'qs';
import { SupplierTag } from "@/enums/supplier";
import { Supplier } from "@/interface/supplier";

export function getSuppliers(statuses: number[], inputName: string, params?: {}): Promise<StdResponse<any>> {
    return axios.get(`/suppliers`, {
        params: {
            name: inputName,
            statuses: statuses,
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}

export function getSupplier(id: number): Promise<StdResponse<any>> {
    return axios.get(`/suppliers/${id}`);
}

export function updateSupplier(id: number, supplier: Supplier){
    return axios.put(`/suppliers/${id}`, supplier);
}

export function inspectSupplier(id: number, accept: boolean, suggestion?: string) {
    let op = accept == true ? SupplierTag.CERTIFIED : SupplierTag.MODIFYING;

    return axios.put(`/suppliers/${id}`, {
        suggestion: suggestion
    }, {
        params: { operate: op },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    });
}