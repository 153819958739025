import { IProps } from "tncet-common/dist/components/color-select/color-select";
import { Tabs , Input, Button, List, Card, Image, Modal, Radio } from "antd";
import  {EnvironmentOutlined, PhoneOutlined, DownloadOutlined, UserOutlined, MobileOutlined, EditOutlined, PlusOutlined}  from "@ant-design/icons";
import './supplier-evaluation.less';
import { addEvaluation, checkEvaluation, getEvaluations } from "../../api/evaluations";
import { Supplier } from "@/interface/supplier";
import { useEffect, useState } from "react";
import { Route, useParams } from 'react-router-dom';
import {useNavigate, useLocation} from "react-router-dom";

import { Avatar, Comment, Tooltip } from 'antd';
import { Rate } from 'antd';
import { Evaluation } from "@/interface/evalutaion";
import { getAvatarSrc } from "@/enums/avatar";
import cookieStore from "@/store/cookie-store";
import { getSupplier, inspectSupplier } from "@/api/supplier";
import { SupplierTag } from "@/enums/supplier";
import SupplierDetail from "./detail";

const {Meta} = Card;


const {TextArea} = Input;
export default function DetailEvaluation(props: any){


    const iniSupply = {id: 0}
    const [supply, setSupply] = useState<Supplier>(iniSupply);
    const [commentData, setCommentData] = useState<Evaluation[]>([]);
    const [evaluating, setEvaluating] = useState<boolean>(false);
    const [starValue, setStarValue] = useState<number>(5)

    let id = props.supplierId;
    const [evaluation, setEvaluation] = useState<string>("");
    const [anonymous, setAnonymous] = useState<boolean>(false);


    useEffect(()=>{
        // console.log(id);
        if (id != undefined){
            getSupplier(id).then(res=>{
                setSupply(res.data);
                // console.log(supply);
                setCanRelease(res.data.userId != cookieStore.getLoginId())
            })

            // 获取评价
            getEvaluations(id).then(res=>{
			   setCommentData(res.data)
            })
        
        }


    },[id])

    
    const [canRelease, setCanRelease] = useState<boolean>(false);
    const [starFilter, setstarFilter] = useState<number>(0);


    interface EvaluationProps{
        data?: Evaluation
    }

    function EvaluationEntry(props: EvaluationProps){
        
        return <div className="evaluationEntry">
            <div className="detailLine">
                <div className="avatarBox">
                    <Avatar src="/assets/avatar/asiaM/asiaM@2x.png"
                    size={67}
                    />
                    <span>{props.data.mobile}</span>
                    {/* <span>{props.data.mobile.slice(0, 2) + "***"+ props.data.mobile.slice(props.data.mobile.length-2)}</span> */}
                </div>
                <div className="contentBox">
                    <div className="textLine">
                        <span>
                            {props.data?.content}

                        </span>
                    </div>
                    <div className="imageLine">
                    <Image title="目前评价的数据里没有图片，可以选择将该div注释掉" src="/assets/avatar/asiaM/asiaM@2x.png" width={64} height={48}/>
                    </div>
                </div>
                <div className="starBox">
                    <Rate disabled defaultValue={props.data?.starRating}/>

                </div>
            </div>
            <div className="infoLine">
                <div className="purchase">
                    <span>
                        已购商品：评价暂无此属性
                    </span>
                </div>
                <div className="time">
                    <span>
                        发布时间：{new Date(props.data.gmtCreate).toLocaleString([],
                             { hour12: false, })}
                    </span>
                </div>

            </div>
        </div>
    }

    function handleRelease(){
        let tmp = evaluation.trim()
        setEvaluation(tmp)
        if (tmp.length===0){
            alert("评价不能为空");
            return;
        }
        let ev = {
            starRating: starValue,
            content: tmp,
            mobile: ""
        }
        // ui要求选择匿名，但目前后台不支持
        if (anonymous){
            ev.mobile = "Anonymous"
        }
        checkEvaluation(ev.content)
        .then((e=>{
            if (e.data.length > 0){
                alert("存在敏感词，请重新修改。")
            }
            else {
                addEvaluation(id, ev).then(()=>{ 
                    window.location.reload();
                })
                .catch((e=>{window.alert(e)}))
            }
        }))
    }

    return <div className="evaluation-scroll">
        <div className="evaluation">
            <div className="evaluation-topLine">
                <div className="titleBox">
                    
                    <span className="title">
                        {supply.name}
                    </span>
                </div>
                <div className="filterBox">
                    <span className="filterTxt">筛选条件：</span>
                    <div className={starFilter===0?"filterItem filterItemChosen":"filterItem"}
                    onClick={()=>{setstarFilter(0)}}>
                        <span className="filterTxt">全部</span>
                    </div>
                    <div className={starFilter===1?"filterItem filterItemChosen":"filterItem"}
                    onClick={()=>{setstarFilter(1)}}>
                        <span className="filterTxt">好评</span>
                        <span className="countTxt">({commentData.filter(e=>(e.starRating>=4)).length})</span>
                    </div>
                    <div className={starFilter===2?"filterItem filterItemChosen":"filterItem"}
                    onClick={()=>{setstarFilter(2)}}>
                        <span className="filterTxt">中/差评</span>
                        <span className="countTxt">({commentData.filter(e=>(e.starRating<4)).length})</span>
                    </div>
                </div>
                <div className="releaseBox">
                    {canRelease&&<Button className="release" onClick={()=>{setEvaluating(true)}}>
                        <span>发布评价</span>
                    </Button>}
                </div>
            </div>
            <div className="evaluation-page">
                {commentData.map(data=>(
                    starFilter==0&&<EvaluationEntry data={data}/> ||
                    (starFilter==1&&data.starRating>=4)&&<EvaluationEntry data={data}/> ||
                    (starFilter==2&&data.starRating<4)&&<EvaluationEntry data={data}/> 
                ))}
            </div>
            <Modal className="evaluation-panel" title={
                <div className="title" style={{fontWeight: 'bolder', textAlign: 'center',borderTop: 'none', fontSize: '22px'}}>商品评价</div>
                } open={evaluating} onCancel={()=>{setEvaluating(false)}}
                footer={
                    <div>
                        <div style={{ textAlign: 'center'}}>
                            <span style={{float: "left", color:"#757575", 
                            fontFamily: "'Hiragino Sans GB', sans-serif",
                            fontSize: "14px",}}>商品评星 :</span>
                            <Rate style={{position: 'relative', top: -10, left: -28}} defaultValue={5} onChange={(e)=>{setStarValue(e)}}/>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '5px'}}>
                        <Button key="ok" onClick={handleRelease} style={
                            {width: '146px',
                            height: '35px',
                            borderRadius: '4px',
                            background: '#1271FF',
                            boxShadow: '-4px 4px 4px 0px rgba(122, 171, 204, 0.05)'
                        }}>
                        <span style={{
                            color: "#FFF",
                            fontFamily: "'Hiragino Sans GB', sans-serif",
                            fontSize: "20px",
                            fontWeight:"400",
                            display: 'block',
                            marginTop: '-3px'
                        }}>发布评价</span>
                        </Button>
                        </div>
                    </div>
                    
                } >
                    <TextArea className="textarea" style={{
                        marginBottom: '3px',
                        height: '200px',
                        backgroundColor: '#F3F3F3',
                        resize: 'none'
                    }} placeholder="对于商品的价格、运送、质量是否满意？点评一下吧！"
                    maxLength={200}
                    onChange = {(e)=>{
                        setEvaluation(e.target.value);
                    }}
                    ></TextArea>
                    <div>
                        <Radio.Group size="small" style={{
                            float: 'right', margin:'1px 0',
                            
                            marginBottom: '3px',
                        }}
                        defaultValue={1}
                        onChange={e=>{setAnonymous(e.target.value===1)}}
                        >
                            <Radio value={0}>
                                <span style={{ color:"#757575", 
                                fontFamily: "'Hiragino Sans GB', sans-serif",
                                fontSize: "14px",}}>公开</span>
                            </Radio>
                            <Radio  value={1}>
                                <span style={{ color:"#757575", 
                                fontFamily: "'Hiragino Sans GB', sans-serif",
                                fontSize: "14px",}}>匿名</span>
                            </Radio>
                        </Radio.Group>
                    </div>
                    
            </Modal>
        </div>
    </div>
}