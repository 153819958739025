type SectionProto = {
    [key: number]: {
        name: string;
        code: string;
        image: any;
        group: string,
        searchImage?: any,
        searchParams?: {},
        customImage?: any, // 自定义
        customParams?: {}, // 自定义
        paramImage?: any, // 显示截面参数数值
    };
};
export const SECTION_PROTO: SectionProto = {
    // HR
    1: {
        name: "H型钢",
        code: "HR-H",
        image: require("../images/HR-H/HR-H@2x.png"),
        group: "热轧",
        // 参数查询时的截面图
        searchImage: require("../images/HR-H/HR-H_1@2x.png"),
        // 查询参数及对应图片
        searchParams: {
            b: require("../images/HR-H/HR-H_1_b@2x.png"),
            h: require("../images/HR-H/HR-H_1_h@2x.png"),
        },
        customImage: require("../images/HR-H/HR-H_3@2x.png"),
        customParams: {
            b: require("../images/HR-H/HR-H_3_b@2x.png"),
            h: require("../images/HR-H/HR-H_3_h@2x.png"),
            r: require("../images/HR-H/HR-H_3_r@2x.png"),
            t_f: require("../images/HR-H/HR-H_3_tf@2x.png"),
            t_w: require("../images/HR-H/HR-H_3_tw@2x.png")
        },
        paramImage: require("../images/HR-H/HR-H_2@2x.png")
    },
    2: {
        name: "T型钢",
        code: "HR-T",
        image: require("../images/HR-T/HR-T@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-T/HR-T_1@2x.png"),
        searchParams: {
            b: require("../images/HR-T/HR-T_1_b@2x.png"),
            h: require("../images/HR-T/HR-T_1_h@2x.png"),
        },
        customImage: require("../images/HR-T/HR-T_3@2x.png"),
        customParams: {
            b: require("../images/HR-T/HR-T_3_b@2x.png"),
            h: require("../images/HR-T/HR-T_3_h@2x.png"),
            r: require("../images/HR-T/HR-T_3_r@2x.png"),
            t_f: require("../images/HR-T/HR-T_3_tf@2x.png"),
            t_w: require("../images/HR-T/HR-T_3_tw@2x.png")
        },
        paramImage: require("../images/HR-T/HR-T_2@2x.png")
    },
    3: {
        name: "工字钢",
        code: "HR-I",
        image: require("../images/HR-I/HR-I@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-I/HR-I_1@2x.png"),
        searchParams: {
            b: require("../images/HR-I/HR-I_1_b@2x.png"),
            h: require("../images/HR-I/HR-I_1_h@2x.png"),
        },
        customImage: require("../images/HR-I/HR-I_3@2x.png"),
        customParams: {
            b: require("../images/HR-I/HR-I_3_b@2x.png"),
            h: require("../images/HR-I/HR-I_3_h@2x.png"),
            r_1: require("../images/HR-I/HR-I_3_r1@2x.png"),
            r: require("../images/HR-I/HR-I_3_r@2x.png"),
            t_f: require("../images/HR-I/HR-I_3_tf@2x.png"),
            t_w: require("../images/HR-I/HR-I_3_tw@2x.png")
        },
        paramImage: require("../images/HR-I/HR-I_2@2x.png")
    },
    4: {
        name: "槽钢",
        code: "HR-C",
        image: require("../images/HR-C/HR-C@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-C/HR-C_1@2x.png"),
        searchParams: {
            b: require("../images/HR-C/HR-C_1_b@2x.png"),
            h: require("../images/HR-C/HR-C_1_h@2x.png"),
        },
        customImage: require("../images/HR-C/HR-C_3@2x.png"),
        customParams: {
            b: require("../images/HR-C/HR-C_3_b@2x.png"),
            h: require("../images/HR-C/HR-C_3_h@2x.png"),
            r_1: require("../images/HR-C/HR-C_3_r1@2x.png"),
            r: require("../images/HR-C/HR-C_3_r@2x.png"),
            t_f: require("../images/HR-C/HR-C_3_tf@2x.png"),
            t_w: require("../images/HR-C/HR-C_3_tw@2x.png")
        },
        paramImage: require("../images/HR-C/HR-C_2@2x.png")
    },
    5: {
        name: "等边角钢",
        code: "HR-L",
        image: require("../images/HR-L/HR-L@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-L/HR-L_1@2x.png"),
        searchParams: {
            b: require("../images/HR-L/HR-L_1_b@2x.png"),
        },
        customImage: require("../images/HR-L/HR-L_3@2x.png"),
        customParams: {
            b: require("../images/HR-L/HR-L_3_b@2x.png"),
            r_1: require("../images/HR-L/HR-L_3_r1@2x.png"),
            r: require("../images/HR-L/HR-L_3_r@2x.png"),
            t: require("../images/HR-L/HR-L_3_t@2x.png")
        },
        paramImage: require("../images/HR-L/HR-L_2@2x.png")
    },
    6: {
        name: "不等边角钢",
        code: "HR-L1",
        image: require("../images/HR-L1/HR-L1@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-L1/HR-L1_1@2x.png"),
        searchParams: {
            b: require("../images/HR-L1/HR-L1_1_b@2x.png"),
            h: require("../images/HR-L1/HR-L1_1_h@2x.png"),
        },
        customImage: require("../images/HR-L1/HR-L1_3@2x.png"),
        customParams: {
            b: require("../images/HR-L1/HR-L1_3_b@2x.png"),
            h: require("../images/HR-L1/HR-L1_3_h@2x.png"),
            r_1: require("../images/HR-L1/HR-L1_3_r1@2x.png"),
            r: require("../images/HR-L1/HR-L1_3_r@2x.png"),
            t: require("../images/HR-L1/HR-L1_3_t@2x.png")
        },
        paramImage: require("../images/HR-L1/HR-L1_2@2x.png")
    },
    7: {
        name: "圆管",
        code: "HR-CT",
        image: require("../images/HR-CT/HR-CT@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-CT/HR-CT_1@2x.png"),
        searchParams: {
            d: require("../images/HR-CT/HR-CT_1_d@2x.png"),
            t: require("../images/HR-CT/HR-CT_1_t@2x.png"),
        },
        customImage: require("../images/HR-CT/HR-CT_3@2x.png"),
        customParams: {
            d: require("../images/HR-CT/HR-CT_3_d@2x.png"),
            t: require("../images/HR-CT/HR-CT_3_t@2x.png")
        },
        paramImage: require("../images/HR-CT/HR-CT_2@2x.png")
    },
    8: {
        name: "扁钢",
        code: "HR-F",
        image: require("../images/HR-F/HR-F@2x.png"),
        group: "热轧",
        searchImage: require("../images/HR-F/HR-F_1@2x.png"),
        searchParams: {
            b: require("../images/HR-F/HR-F_1_b@2x.png"),
            t: require("../images/HR-F/HR-F_1_t@2x.png"),
        },
        customImage: require("../images/HR-F/HR-F_3@2x.png"),
        customParams: {
            b: require("../images/HR-F/HR-F_3_b@2x.png"),
            t: require("../images/HR-F/HR-F_3_t@2x.png")
        },
        paramImage: require("../images/HR-F/HR-F_2@2x.png")
    },


    // CF
    21: {
        name: "卷边C型",
        code: "CF-C",
        image: require("../images/CF-C/CF-C@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-C/CF-C_1@2x.png"),
        searchParams: {
            b: require("../images/CF-C/CF-C_1_b@2x.png"),
            h: require("../images/CF-C/CF-C_1_h@2x.png"),
        },
        customImage: require("../images/CF-C/CF-C_3@2x.png"),
        customParams: {
            a: require("../images/CF-C/CF-C_3_a@2x.png"),
            b: require("../images/CF-C/CF-C_3_b@2x.png"),
            h: require("../images/CF-C/CF-C_3_h@2x.png"),
            r: require("../images/CF-C/CF-C_3_r@2x.png"),
            t: require("../images/CF-C/CF-C_3_t@2x.png")
        },
        paramImage: require("../images/CF-C/CF-C_2@2x.png")
    },
    22: {
        name: "C型",
        code: "CF-C1",
        image: require("../images/CF-C1/CF-C1@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-C1/CF-C1_1@2x.png"),
        searchParams: {
            b: require("../images/CF-C1/CF-C1_1_b@2x.png"),
            h: require("../images/CF-C1/CF-C1_1_h@2x.png"),
        },
        customImage: require("../images/CF-C1/CF-C1_3@2x.png"),
        customParams: {
            b: require("../images/CF-C1/CF-C1_3_b@2x.png"),
            h: require("../images/CF-C1/CF-C1_3_h@2x.png"),
            r: require("../images/CF-C1/CF-C1_3_r@2x.png"),
            t: require("../images/CF-C1/CF-C1_3_t@2x.png")
        },
        paramImage: require("../images/CF-C1/CF-C1_2@2x.png")
    },
    23: {
        name: "斜卷边Z型",
        code: "CF-Z",
        image: require("../images/CF-Z/CF-Z@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-Z/CF-Z_1@2x.png"),
        searchParams: {
            b: require("../images/CF-Z/CF-Z_1_b@2x.png"),
            h: require("../images/CF-Z/CF-Z_1_h@2x.png"),
        },
        customImage: require("../images/CF-Z/CF-Z_3@2x.png"),
        customParams: {
            a: require("../images/CF-Z/CF-Z_3_a@2x.png"),
            b: require("../images/CF-Z/CF-Z_3_b@2x.png"),
            h: require("../images/CF-Z/CF-Z_3_h@2x.png"),
            r: require("../images/CF-Z/CF-Z_3_r@2x.png"),
            t: require("../images/CF-Z/CF-Z_3_t@2x.png"),
            θ: require("../images/CF-Z/CF-Z_3_θ@2x.png"),
        },
        paramImage: require("../images/CF-Z/CF-Z_2@2x.png")
    },
    24: {
        name: "直卷边Z型",
        code: "CF-Z1",
        image: require("../images/CF-Z1/CF-Z1@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-Z1/CF-Z1_1@2x.png"),
        searchParams: {
            b: require("../images/CF-Z1/CF-Z1_1_b@2x.png"),
            h: require("../images/CF-Z1/CF-Z1_1_h@2x.png"),
        },
        customImage: require("../images/CF-Z1/CF-Z1_3@2x.png"),
        customParams: {
            a: require("../images/CF-Z1/CF-Z1_3_a@2x.png"),
            b: require("../images/CF-Z1/CF-Z1_3_b@2x.png"),
            h: require("../images/CF-Z1/CF-Z1_3_h@2x.png"),
            r: require("../images/CF-Z1/CF-Z1_3_r@2x.png"),
            t: require("../images/CF-Z1/CF-Z1_3_t@2x.png")
        },
        paramImage: require("../images/CF-Z1/CF-Z1_2@2x.png")
    },
    25: {
        name: "方钢管",
        code: "CF-ST",
        image: require("../images/CF-ST/CF-ST@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-ST/CF-ST_1@2x.png"),
        searchParams: {
            b: require("../images/CF-ST/CF-ST_1_b@2x.png"),
        },
        customImage: require("../images/CF-ST/CF-ST_3@2x.png"),
        customParams: {
            b: require("../images/CF-ST/CF-ST_3_b@2x.png"),
            r: require("../images/CF-ST/CF-ST_3_r@2x.png"),
            t: require("../images/CF-ST/CF-ST_3_t@2x.png")
        },
        paramImage: require("../images/CF-ST/CF-ST_2@2x.png")
    },
    26: {
        name: "矩形管",
        code: "CF-RT",
        image: require("../images/CF-RT/CF-RT@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-RT/CF-RT_1@2x.png"),
        searchParams: {
            b: require("../images/CF-RT/CF-RT_1_b@2x.png"),
            h: require("../images/CF-RT/CF-RT_1_h@2x.png"),
        },
        customImage: require("../images/CF-RT/CF-RT_3@2x.png"),
        customParams: {
            b: require("../images/CF-RT/CF-RT_3_b@2x.png"),
            h: require("../images/CF-RT/CF-RT_3_h@2x.png"),
            r: require("../images/CF-RT/CF-RT_3_r@2x.png"),
            t: require("../images/CF-RT/CF-RT_3_t@2x.png")
        },
        paramImage: require("../images/CF-RT/CF-RT_2@2x.png")
    },
    27: {
        name: "等边L型",
        code: "CF-L",
        image: require("../images/CF-L/CF-L@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-L/CF-L_1@2x.png"),
        searchParams: {
            b: require("../images/CF-L/CF-L_1_b@2x.png"),
        },
        customImage: require("../images/CF-L/CF-L_3@2x.png"),
        customParams: {
            b: require("../images/CF-L/CF-L_3_b@2x.png"),
            r: require("../images/CF-L/CF-L_3_r@2x.png"),
            t: require("../images/CF-L/CF-L_3_t@2x.png")
        },
        paramImage: require("../images/CF-L/CF-L_2@2x.png")
    },
    28: {
        name: "卷等边L",
        code: "CF-L1",
        image: require("../images/CF-L1/CF-L1@2x.png"),
        group: "冷弯",
        searchImage: require("../images/CF-L1/CF-L1_1@2x.png"),
        searchParams: {
            b: require("../images/CF-L1/CF-L1_1_b@2x.png"),
        },
        customImage: require("../images/CF-L1/CF-L1_3@2x.png"),
        customParams: {
            a: require("../images/CF-L1/CF-L1_3_a@2x.png"),
            b: require("../images/CF-L1/CF-L1_3_b@2x.png"),
            r: require("../images/CF-L1/CF-L1_3_r@2x.png"),
            t: require("../images/CF-L1/CF-L1_3_t@2x.png")
        },
        paramImage: require("../images/CF-L1/CF-L1_2@2x.png")
    },

    // WS
    41: {
        name: "焊接H形",
        code: "WS-H",
        image: require("../images/WS-H/WS-H@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-H/WS-H_1@2x.png"),
        searchParams: {
            b_1: require("../images/WS-H/WS-H_1_b1@2x.png"),
            b_2: require("../images/WS-H/WS-H_1_b2@2x.png"),
            h: require("../images/WS-H/WS-H_1_h@2x.png"),
        },
        customImage: require("../images/WS-H/WS-H_3@2x.png"),
        customParams: {
            b_1: require("../images/WS-H/WS-H_3_b1@2x.png"),
            b_2: require("../images/WS-H/WS-H_3_b2@2x.png"),
            h: require("../images/WS-H/WS-H_3_h@2x.png"),
            t_f1: require("../images/WS-H/WS-H_3_tf1@2x.png"),
            t_f2: require("../images/WS-H/WS-H_3_tf2@2x.png"),
            t_w: require("../images/WS-H/WS-H_3_tw@2x.png")
        },
        paramImage: require("../images/WS-H/WS-H_2@3x.png")
    },
    42: {
        name: "焊接T形",
        code: "WS-T",
        image: require("../images/WS-T/WS-T@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-T/WS-T_1@2x.png"),
        searchParams: {
            b: require("../images/WS-T/WS-T_1_b@2x.png"),
            h: require("../images/WS-T/WS-T_1_h@2x.png"),
        },
        customImage: require("../images/WS-T/WS-T_3@2x.png"),
        customParams: {
            b: require("../images/WS-T/WS-T_3_b@2x.png"),
            h: require("../images/WS-T/WS-T_3_h@2x.png"),
            t_f: require("../images/WS-T/WS-T_3_tf@2x.png"),
            t_w: require("../images/WS-T/WS-T_3_tw@2x.png")
        },
        paramImage: require("../images/WS-T/WS-T_2@3x.png")
    },
    43: {
        name: "箱形",
        code: "WS-RT",
        image: require("../images/WS-RT/WS-RT@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-RT/WS-RT_1@2x.png"),
        searchParams: {
            b: require("../images/WS-RT/WS-RT_1_b@2x.png"),
            h: require("../images/WS-RT/WS-RT_1_h@2x.png"),
        },
        customImage: require("../images/WS-RT/WS-RT_3@2x.png"),
        customParams: {
            b: require("../images/WS-RT/WS-RT_3_b@2x.png"),
            h: require("../images/WS-RT/WS-RT_3_h@2x.png"),
            t_f1: require("../images/WS-RT/WS-RT_3_tf1@2x.png"),
            t_f2: require("../images/WS-RT/WS-RT_3_tf2@2x.png"),
            t_w1: require("../images/WS-RT/WS-RT_3_tw1@2x.png"),
            t_w2: require("../images/WS-RT/WS-RT_3_tw2@2x.png")
        },
        paramImage: require("../images/WS-RT/WS-RT_2@3x.png")
    },
    44: {
        name: "外伸箱形",
        code: "WS-RT1",
        image: require("../images/WS-RT1/WS-RT1@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-RT1/WS-RT1_1@2x.png"),
        searchParams: {
            b: require("../images/WS-RT1/WS-RT1_1_b@2x.png"),
            h: require("../images/WS-RT1/WS-RT1_1_h@2x.png"),
        },
        customImage: require("../images/WS-RT1/WS-RT1_3@2x.png"),
        customParams: {
            b_1: require("../images/WS-RT1/WS-RT1_3_b1@2x.png"),
            b: require("../images/WS-RT1/WS-RT1_3_b@2x.png"),
            h: require("../images/WS-RT1/WS-RT1_3_h@2x.png"),
            t_f1: require("../images/WS-RT1/WS-RT1_3_tf1@2x.png"),
            t_f2: require("../images/WS-RT1/WS-RT1_3_tf2@2x.png"),
            t_w: require("../images/WS-RT1/WS-RT1_3_tw@2x.png")
        },
        paramImage: require("../images/WS-RT1/WS-RT1_2@3x.png")
    },
    45: {
        name: "圆管",
        code: "WS-CT",
        image: require("../images/WS-CT/WS-CT@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-CT/WS-CT_1@2x.png"),
        searchParams: {
            d: require("../images/WS-CT/WS-CT_1_d@2x.png"),
            t: require("../images/WS-CT/WS-CT_1_t@2x.png"),
        },
        customImage: require("../images/WS-CT/WS-CT_3@2x.png"),
        customParams: {
            d: require("../images/WS-CT/WS-CT_3_d@2x.png"),
            t: require("../images/WS-CT/WS-CT_3_t@2x.png")
        },
        paramImage: require("../images/WS-CT/WS-CT_2@3x.png")
    },
    46: {
        name: "十字形",
        code: "WS-CR",
        image: require("../images/WS-CR/WS-CR@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-CR/WS-CR_1@2x.png"),
        searchParams: {
            b: require("../images/WS-CR/WS-CR_1_b@2x.png"),
            h: require("../images/WS-CR/WS-CR_1_h@2x.png"),
        },
        customImage: require("../images/WS-CR/WS-CR_3@2x.png"),
        customParams: {
            b_1: require("../images/WS-CR/WS-CR_3_b1@2x.png"),
            b: require("../images/WS-CR/WS-CR_3_b@2x.png"),
            h_1: require("../images/WS-CR/WS-CR_3_h1@2x.png"),
            h: require("../images/WS-CR/WS-CR_3_h@2x.png"),
            t_f: require("../images/WS-CR/WS-CR_3_tf@2x.png"),
            t_w: require("../images/WS-CR/WS-CR_3_tw@2x.png")
        },
        paramImage: require("../images/WS-CR/WS-CR_2@3x.png")
    },
    47: {
        name: "日字形",
        code: "WS-TC",
        image: require("../images/WS-TC/WS-TC@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-TC/WS-TC_1@2x.png"),
        searchParams: {
            b: require("../images/WS-TC/WS-TC_1_b@2x.png"),
            h: require("../images/WS-TC/WS-TC_1_h@2x.png"),
        },
        customImage: require("../images/WS-TC/WS-TC_3@2x.png"),
        customParams: {
            b: require("../images/WS-TC/WS-TC_3_b@2x.png"),
            h: require("../images/WS-TC/WS-TC_3_h@2x.png"),
            t_f1: require("../images/WS-TC/WS-TC_3_tf1@2x.png"),
            t_f2: require("../images/WS-TC/WS-TC_3_tf2@2x.png"),
            t_w1: require("../images/WS-TC/WS-TC_3_tw1@2x.png"),
            t_w2: require("../images/WS-TC/WS-TC_3_tw2@2x.png")
        },
        paramImage: require("../images/WS-TC/WS-TC_2@3x.png")
    },
    48: {
        name: "焊接C形",
        code: "WS-C",
        image: require("../images/WS-C/WS-C@2x.png"),
        group: "焊接",
        searchImage: require("../images/WS-C/WS-C_1@2x.png"),
        searchParams: {
            b_1: require("../images/WS-C/WS-C_1_b1@2x.png"),
            b_2: require("../images/WS-C/WS-C_1_b2@2x.png"),
            h: require("../images/WS-C/WS-C_1_h@2x.png"),
        },
        customImage: require("../images/WS-C/WS-C_3@2x.png"),
        customParams: {
            b_1: require("../images/WS-C/WS-C_3_b1@2x.png"),
            b_2: require("../images/WS-C/WS-C_3_b2@2x.png"),
            h: require("../images/WS-C/WS-C_3_h@2x.png"),
            t_f1: require("../images/WS-C/WS-C_3_tf1@2x.png"),
            t_f2: require("../images/WS-C/WS-C_3_tf2@2x.png"),
            t_w: require("../images/WS-C/WS-C_3_tw@2x.png")
        },
        paramImage: require("../images/WS-C/WS-C_2@3x.png")
    },

    // SC
    61: {
        name: "工字形1",
        code: "SC-H1",
        image: require("../images/SC-H1/SC-H1@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-H1/SC-H1_1@2x.png"),
        searchParams: {
            b: require("../images/SC-H1/SC-H1_1_b@2x.png"),
            b_1: require("../images/SC-H1/SC-H1_1_b1@2x.png"),
            h: require("../images/SC-H1/SC-H1_1_h@2x.png"),
        },
        customImage: require("../images/SC-H1/SC-H1_3@2x.png"),
        customParams: {
            b: require("../images/SC-H1/SC-H1_3_b@2x.png"),
            f_b: require("../images/SC-H1/SC-H1_3_fb@2x.png")
        },
        paramImage: require("../images/SC-H1/SC-H1_2@3x.png")
    },
    62: {
        name: "工字形2",
        code: "SC-H2",
        image: require("../images/SC-H2/SC-H2@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-H2/SC-H2_1@2x.png"),
        searchParams: {
            b: require("../images/SC-H2/SC-H2_1_b@2x.png"),
            h: require("../images/SC-H2/SC-H2_1_h@2x.png"),
            h_1: require("../images/SC-H2/SC-H2_1_h1@2x.png"),
        },
        customImage: require("../images/SC-H2/SC-H2_3@2x.png"),
        customParams: {
            f_b: require("../images/SC-H2/SC-H2_3_fb@2x.png"),
            h_0: require("../images/SC-H2/SC-H2_3_h0@2x.png"),
            h: require("../images/SC-H2/SC-H2_3_h@2x.png")
        },
        paramImage: require("../images/SC-H2/SC-H2_2@3x.png")
    },
    63: {
        name: "工字形3",
        code: "SC-H3",
        image: require("../images/SC-H3/SC-H3@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-H3/SC-H3_1@2x.png"),
        searchParams: {
            b: require("../images/SC-H3/SC-H3_1_b@2x.png"),
            h: require("../images/SC-H3/SC-H3_1_h@2x.png"),
            h_1: require("../images/SC-H3/SC-H3_1_h1@2x.png"),
        },
        customImage: require("../images/SC-H3/SC-H3_3@2x.png"),
        customParams: {
            f_b: require("../images/SC-H3/SC-H3_3_fb@2x.png"),
            h_0: require("../images/SC-H3/SC-H3_3_h0@2x.png"),
            h: require("../images/SC-H3/SC-H3_3_h@2x.png")
        },
        paramImage: require("../images/SC-H3/SC-H3_2@2x.png")
    },
    64: {
        name: "工字形4",
        code: "SC-H4",
        image: require("../images/SC-H4/SC-H4@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-H4/SC-H4_1@2x.png"),
        searchParams: {
            b_1: require("../images/SC-H4/SC-H4_1_b1@2x.png"),
            b_2: require("../images/SC-H4/SC-H4_1_b2@2x.png"),
            h: require("../images/SC-H4/SC-H4_1_h@2x.png"),
        },
        customImage: require("../images/SC-H4/SC-H4_3@2x.png"),
        customParams: {
            f_b: require("../images/SC-H4/SC-H4_3_fb@2x.png"),
            h_0: require("../images/SC-H4/SC-H4_3_h0@2x.png"),
            h: require("../images/SC-H4/SC-H4_3_h@2x.png")
        },
        paramImage: require("../images/SC-H4/SC-H4_2@2x.png")
    },
    65: {
        name: "工字形5",
        code: "SC-H5",
        image: require("../images/SC-H5/SC-H5@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-H5/SC-H5_1@2x.png"),
        searchParams: {
            b: require("../images/SC-H5/SC-H5_1_b@2x.png"),
            b_1: require("../images/SC-H5/SC-H5_1_b1@2x.png"),
            h: require("../images/SC-H5/SC-H5_1_h@2x.png"),
            h_1: require("../images/SC-H5/SC-H5_1_h1@2x.png"),
        },
        customImage: require("../images/SC-H5/SC-H5_3@2x.png"),
        customParams: {
            a_1: require("../images/SC-H5/SC-H5_3_a1@2x.png"),
            b: require("../images/SC-H5/SC-H5_3_b@2x.png"),
            f_b: require("../images/SC-H5/SC-H5_3_fb@2x.png"),
            h: require("../images/SC-H5/SC-H5_3_h@2x.png")
        },
        paramImage: require("../images/SC-H5/SC-H5_2@2x.png")
    },
    66: {
        name: "T形1",
        code: "SC-T1",
        image: require("../images/SC-T1/SC-T1@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-T1/SC-T1_1@2x.png"),
        searchParams: {
            b_1: require("../images/SC-T1/SC-T1_1_b1@2x.png"),
            b_2: require("../images/SC-T1/SC-T1_1_b2@2x.png"),
            h: require("../images/SC-T1/SC-T1_1_h@2x.png"),
        },
        customImage: require("../images/SC-T1/SC-T1_3@2x.png"),
        customParams: {
            h: require("../images/SC-T1/SC-T1_3_h@2x.png")
        },
        paramImage: require("../images/SC-T1/SC-T1_2@2x.png")
    },
    67: {
        name: "T形2",
        code: "SC-T2",
        image: require("../images/SC-T2/SC-T2@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-T2/SC-T2_1@2x.png"),
        searchParams: {
            b_1: require("../images/SC-T2/SC-T2_1_b1@2x.png"),
            b_2: require("../images/SC-T2/SC-T2_1_b2@2x.png"),
            h: require("../images/SC-T2/SC-T2_1_h@2x.png"),
        },
        customImage: require("../images/SC-T2/SC-T2_3@2x.png"),
        customParams: {
            h: require("../images/SC-T2/SC-T2_3_h@2x.png")
        },
        paramImage: require("../images/SC-T2/SC-T2_2@2x.png")
    },
    68: {
        name: "T形3",
        code: "SC-T3",
        image: require("../images/SC-T3/SC-T3@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-T3/SC-T3_1@2x.png"),
        searchParams: {
            b_1: require("../images/SC-T3/SC-T3_1_b1@2x.png"),
            b_2: require("../images/SC-T3/SC-T3_1_b2@2x.png"),
            h: require("../images/SC-T3/SC-T3_1_h@2x.png"),
        },
        customImage: require("../images/SC-T3/SC-T3_3@2x.png"),
        customParams: {
            h: require("../images/SC-T3/SC-T3_3_h@2x.png")
        },
        paramImage: require("../images/SC-T3/SC-T3_2@2x.png")
    },
    69: {
        name: "T形4",
        code: "SC-T4",
        image: require("../images/SC-T4/SC-T4@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-T4/SC-T4_1@2x.png"),
        searchParams: {
            b_1: require("../images/SC-T4/SC-T4_1_b1@2x.png"),
            b_2: require("../images/SC-T4/SC-T4_1_b2@2x.png"),
            h: require("../images/SC-T4/SC-T4_1_h@2x.png"),
        },
        customImage: require("../images/SC-T4/SC-T4_3@2x.png"),
        customParams: {
            h: require("../images/SC-T4/SC-T4_3_h@2x.png")
        },
        paramImage: require("../images/SC-T4/SC-T4_2@2x.png")
    },
    70: {
        name: "T形5",
        code: "SC-T5",
        image: require("../images/SC-T5/SC-T5@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-T5/SC-T5_1@2x.png"),
        searchParams: {
            b: require("../images/SC-T5/SC-T5_1_b@2x.png"),
            b_1: require("../images/SC-T5/SC-T5_1_b1@2x.png"),
            h: require("../images/SC-T5/SC-T5_1_h@2x.png"),
        },
        customImage: require("../images/SC-T5/SC-T5_3@2x.png"),
        customParams: {
            b: require("../images/SC-T5/SC-T5_3_b@2x.png"),
            f_b: require("../images/SC-T5/SC-T5_3_fb@2x.png")
        },
        paramImage: require("../images/SC-T5/SC-T5_2@2x.png")
    },
    71: {
        name: "箱形1",
        code: "SC-BL",
        image: require("../images/SC-BL/SC-BL@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-BL/SC-BL_1@2x.png"),
        searchParams: {
            b: require("../images/SC-BL/SC-BL_1_b@2x.png"),
            h: require("../images/SC-BL/SC-BL_1_h@2x.png"),
            h_1: require("../images/SC-BL/SC-BL_1_h1@2x.png"),
        },
        customImage: require("../images/SC-BL/SC-BL_3@2x.png"),
		customParams: {
			h: require("../images/SC-BL/SC-BL_3_h@2x.png")
		},
        paramImage: require("../images/SC-BL/SC-BL_2@2x.png")
    },
    72: {
        name: "箱形2",
        code: "SC-BC",
        image: require("../images/SC-BC/SC-BC@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-BC/SC-BC_1@2x.png"),
        searchParams: {
            b: require("../images/SC-BC/SC-BC_1_b@2x.png"),
            b_1: require("../images/SC-BC/SC-BC_1_b1@2x.png"),
            h: require("../images/SC-BC/SC-BC_1_h@2x.png"),
        },
        customImage: require("../images/SC-BC/SC-BC_3@2x.png"),
        customParams: {
            b: require("../images/SC-BC/SC-BC_3_b@2x.png"),
            f_b: require("../images/SC-BC/SC-BC_3_fb@2x.png")
        },
        paramImage: require("../images/SC-BC/SC-BC_2@2x.png")
    },
    73: {
        name: "十字形1",
        code: "SC-CL",
        image: require("../images/SC-CL/SC-CL@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-CL/SC-CL_1@2x.png"),
        searchParams: {
            b: require("../images/SC-CL/SC-CL_1_b@2x.png"),
            b_1: require("../images/SC-CL/SC-CL_1_b1@2x.png"),
            h: require("../images/SC-CL/SC-CL_1_h@2x.png"),
            h_1: require("../images/SC-CL/SC-CL_1_h1@2x.png"),
        },
        customImage: require("../images/SC-CL/SC-CL_3@2x.png"),
        customParams: {
            a_1: require("../images/SC-CL/SC-CL_3_a1@2x.png"),
            b: require("../images/SC-CL/SC-CL_3_b@2x.png"),
            f_b: require("../images/SC-CL/SC-CL_3_fb@2x.png"),
            h: require("../images/SC-CL/SC-CL_3_h@2x.png")
        },
        paramImage: require("../images/SC-CL/SC-CL_2@2x.png")
    },
    74: {
        name: "十字形2",
        code: "SC-CT",
        image: require("../images/SC-CT/SC-CT@2x.png"),
        group: "组合",
        searchImage: require("../images/SC-CT/SC-CT_1@2x.png"),
        searchParams: {
            b: require("../images/SC-CT/SC-CT_1_b@2x.png"),
            b_1: require("../images/SC-CT/SC-CT_1_b1@2x.png"),
            h: require("../images/SC-CT/SC-CT_1_h@2x.png"),
        },
        customImage: require("../images/SC-CT/SC-CT_3@2x.png"),
        customParams: {

        },
        paramImage: require("../images/SC-CT/SC-CT_2@2x.png")
    },
}