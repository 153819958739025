import { Coating } from "@/interface/coating";
import cookieStore from "@/store/cookie-store";
import { Button, Checkbox, Col, Divider, Form, Image, Input, InputNumber, Popconfirm, Popover, Row, Select, Space, Table, Upload, message } from "antd";
import { useContext, useEffect, useState } from "react";
import './goods-coating.less'
import './goods-topline.less'
import { ContextMenuFC, DragModal, FileUploadParam, UploadFileLocale } from "tncet-common";
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { addCoating, deleteCoating, getCoatings, updateCoating } from "@/api/coating";
import { UploadFileContext } from "@/App";
import { getFileDownloadUrl } from "@/api/file";
import { getSupplier } from "@/api/supplier";
import { Supplier } from "@/interface/supplier";

interface Iprops {
    supplierId: number;
    onTabChange: (number)=>any
}


interface PickerProps {
    label: string;
    value: any;
    inputValue?: string;
}

export default function GoodsCoating(props: Iprops) {
    const [coatings, setCoatings] = useState<Coating[]>();
    const user = cookieStore.getUser();
    const [contactId, setContactId] = useState<number>();

    const [selectedCoating, setSelectedCoating] = useState<Coating>(); // 选中的涂料

    const [dmVis, setDmVis] = useState<boolean>(false);
    const [dmMode, setDmMode] = useState<string>("add"); // 用于记录弹窗是用于新增还是修改

    const [cmTop, setCmTop] = useState<number>();
    const [cmLeft, setCmLeft] = useState<number>();
    const [cmVis, setCmVis] = useState<boolean>(false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const uploadFileContext = useContext(UploadFileContext);
    const [uploadFileList, setUploadFileList] = useState<any>([]);
    const [uploadFile, setUplaodFile] = useState<FileUploadParam>();
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);


    const [supplier, setSupplier] = useState<Supplier>();

    const [previewImage,setPreviewImage] = useState<boolean>(false);


    const places: PickerProps[] = [
        {
            label: "室内",
            value: "室内"
        },
        {
            label: "室外",
            value: "室外"
        }
    ]

    const mediums: PickerProps[] = [
        {
            label: "水基性",
            value: "水基性"
        },
        {
            label: "溶剂性",
            value: "溶剂性"
        }
    ]

    const mechanisms: PickerProps[] = [
        {
            label: "膨胀型",
            value: "膨胀型"
        },
        {
            label: "非膨胀型",
            value: "非膨胀型"
        }
    ]

    const densityTypes: PickerProps[] = [
        {
            label: "低密度（0.3g/cm3）",
            inputValue: "0.3",
            value: 1
        },
        {
            label: "中密度（0.8g/cm3）",
            inputValue: "0.8",
            value: 2
        },
        {
            label: "高密度（1.5g/cm3）",
            inputValue: "1.5",
            value: 3
        }
    ]

    const fireResistances: PickerProps[] = [
        {
            label: "Fp0.50",
            value: "Fp0.50"
        },
        {
            label: "Fp1.00",
            value: "Fp1.00"
        },
        {
            label: "Fp1.50",
            value: "Fp1.50"
        },
        {
            label: "Fp2.00",
            value: "Fp2.00"
        },
        {
            label: "Fp2.50",
            value: "Fp2.50"
        },
        {
            label: "Fp3.00",
            value: "Fp3.00"
        },
        {
            label: "Ft0.50",
            value: "Ft0.50"
        },
        {
            label: "Ft1.00",
            value: "Ft1.00"
        },
        {
            label: "Ft1.50",
            value: "Ft1.50"
        },
        {
            label: "Ft2.00",
            value: "Ft2.00"
        },
        {
            label: "Ft2.50",
            value: "Ft2.50"
        },
        {
            label: "Ft3.00",
            value: "Ft3.00"
        },
    ]

    useEffect(() => {
        if (!!props.supplierId) {
            generateData(props.supplierId);
        }
    }, [props])

    const generateData = (supplierId: number) => {
        getCoatings({ supplierId: supplierId }).then(res => {
            if (res.status === 200) {
                let coatings: Coating[] = res.data || [];
                setCoatings(coatings);
            }
        })
        getSupplier(props.supplierId).then(res => {
            if (res.status === 200) {
                setContactId(res.data.userId);
                setSupplier(res.data)
                setcanRelease(user.type===0 || user.id === res.data.userId)
            }
        })
    }

    const uploadCallbackFn = (uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
            getFileDownloadUrl(uploadFile.md5 + uploadFile.suffix).then(res => {
                setUplaodFile(uploadFile);
                setSelectedCoating({
                    ...selectedCoating,
                    md5: uploadFile?.md5,
                    suffix: uploadFile?.suffix,
                    url: res.data,
                })
                resolve(true);
            })
        })
    }

    useEffect(() => {
        if (uploadFileList.length === 0) return;
        let files: UploadFileLocale[] = uploadFileList.map(item => {
            return {
                file: item,
                callbackFn: uploadCallbackFn
            }
        })
        uploadFileContext.startUpload(files); //上传的关键
        setUploadFileList([]);
    }, [uploadFileList])

    const beforeUpload = (file, fileList) => {
        setCmVis(false);
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        let isImage = file && acceptedImageTypes.includes(file.type);
        if (!isImage) {
            message.warning("只能上传图片！")
        } else {
            if (uploadFileList.length === 0) {
                setUploadFileList([...fileList]);
            }
        }
        return false;
    }

    const onRemoveImage = () => {
        let tmp = selectedCoating;
        tmp.md5 = null;
        tmp.suffix = null;
        tmp.url = null;
        setSelectedCoating({ ...tmp });
        setUplaodFile(null);
        setUploadFileList([]);
        setIsDeleteImage(true);
    }

    const onOpenDm = (ope: string, row?: Coating) => {
        setSelectedCoating({ ...row });
        if (ope === "add") {
            setDmMode("add");
            form.resetFields();
        } else if (ope === "edit") {
            setDmMode("edit");
            form.setFieldsValue(
                {
                    ...row,
                }
            );

        }
        setDmVis(true);
    }

    const onImageRightClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let clientX = e.clientX;
        let clientY = e.clientY;
        setCmLeft(clientX);
        setCmTop(clientY);
        setCmVis(true);
    }

    const onInsertCoating = (values) => {

        let newCoating: Coating = {
            ...values,
            deleteFile: isDeleteImage,
        }

        if (!!uploadFile) {
            newCoating['file'] = {
                fileMD5: uploadFile.md5,
                suffix: uploadFile.suffix,
                filePath: uploadFile.filePath,
                fileSize: uploadFile.fileSize,
            };
        }

        setLoading(true);
        if (dmMode === "add") {
            addCoating(props.supplierId, newCoating).then(res => {
                if (res.status === 200) {
                    setDmVis(false);
                    setLoading(false);
                    generateData(props.supplierId);
                } else {
                    message.warning("添加失败");
                    setLoading(false);
                }
            }).catch(e => {
                message.warning("添加失败");
                setLoading(false);
            })
        } else if (dmMode === "edit") {
            updateCoating(selectedCoating.id, newCoating).then(res => {
                if (res.status === 200) {
                    setDmVis(false);
                    setLoading(false);
                    generateData(props.supplierId);
                } else {
                    message.warning("修改失败");
                    setLoading(false);
                }
            }).catch(e => {
                message.warning("修改失败");
                setLoading(false);
            })
        }

    }

    const handleDelete = (paint: Coating) => {
        setDeleteLoading(true);
        deleteCoating(paint.id).then(res => {
            if (res.status === 200) {
                let tmp = coatings.filter(item => item.id !== paint.id);
                setCoatings(tmp);
            } else {
                message.warning("删除失败");
            }
            setDeleteLoading(false);
        }).catch(e => {
            message.warning("删除失败");
            setDeleteLoading(false);
        })
    }

    const onDensitySelectChange = (value) => {
        let density = densityTypes.find(item => item.value === value).inputValue;
        form.setFieldValue("density", density);
    }

    
    // 新ui样式
    const [itemType, setitemType] = useState<number>(3)
    const [canRelease, setcanRelease] = useState<boolean>(false)

    function GoodsTopLine(){
        return(
        <div className="goods-topLine">
            <div className="titleBox">

                <span className="title">
                    {supplier?.name}
                </span>
            </div>
            <div className="filterBox">
                <span className="filterTxt">商品类型：</span>
                <div className={itemType===1?"filterItem filterItemChosen":"filterItem"}
                onClick={()=>{setitemType(1);props.onTabChange(1)}}>
                    <span className="filterTxt">钢材</span>
                </div>
                <div className={itemType===2?"filterItem filterItemChosen":"filterItem"}
                onClick={()=>{setitemType(2);props.onTabChange(2)}}>
                    <span className="filterTxt">油漆</span>
                </div>
                <div className={itemType===3?"filterItem filterItemChosen":"filterItem"}
                 onClick={()=>{setitemType(3);props.onTabChange(3)}}>
                    <span className="filterTxt">防火涂料</span>
                </div>
            </div>
            <div className="releaseBox">
                {canRelease&&<Button className="release" onClick={() => onOpenDm("add")}>
                    <span>上架</span>
                </Button>}
            </div>
        </div>
        )
    }

    
    function CoatingEntry(props: {data: Coating}){
        const [PopoverOn, setPopoverOn] = useState<boolean>(false);
        const [imageUrl, setImageUrl] = useState<string>(props.data.url);
        
        return (
            <div className="coatingEntry">
                <div className="imageBox">
                    <Image width={81} height={75} className="image" src={imageUrl||"/assets/coating.png"}/>
                </div>
                <div className="rightBox">
                    <div className="infoLine">
                        <span>
                            {props.data.name} ({props.data.model})
                        </span>
                        {canRelease&&<Popover
                         placement="right"
                          content={<div>
                            <div className="xxx" style={{width:'100%',marginTop: '-10px'}}
                            onClick={() => onOpenDm("edit", props.data)}>
                                <Button style={{border: 0}}>编辑</Button>
                                </div>
                            <div style={{marginBottom: '-10px'}}
                            onClick={()=>{handleDelete(props.data)}}>
                                <Button style={{border: 0, color: 'red'}}>删除</Button>
                            </div>
                          </div>}
                        //   title="Title"
                          trigger="click"
                          open={PopoverOn}
                          onOpenChange={(e)=>{setPopoverOn(e)}}
                          
                        >
                        <EllipsisOutlined style={{fontSize: "24px"}} onClick={()=>{}}/>
                        </Popover>}
                    </div>
                    <div className="priceLine">
                        <span>
                            {props.data.price&&props.data.price+'元/桶'||'价格面议'}&nbsp;&nbsp;({props.data.specification}kg)
                        </span>

                    </div>
                    <div className="infoLine2">
                        <span>{props.data.place}</span>
                        <span>{props.data.medium}</span>
                        <span>{props.data.mechanism}</span>
                        <span>{props.data.fireResistance}</span>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="coating">
                <GoodsTopLine />
                <div className="coating-page">
                    {coatings?.map(data=>(
                         <div key={data?.id}>
                        <CoatingEntry data={data}/>
                        </div>
                    ))}
                </div>
            </div>
            {canRelease&&<DragModal
                visible={dmVis}
                width={800}
                onClose={() => setDmVis(false)}
                mask
                destroyOnClose
            >
                <div className="add-coating-dm-container">
                    <div className="title">
                        {dmMode === "add" ? "添加防火涂料" : "编辑防火涂料"}
                    </div>
                    <Form className="form" form={form} autoComplete="false" onFinish={onInsertCoating}>
                        <Row>
                            <Col span={11} className="col1">
                                <Form.Item label="名称" name="name" rules={[{ required: true, message: "名称不能为空" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="执行标准" name="standard" rules={[{ required: true, message: "执行标准不能为空" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="型号" name="model" rules={[{ required: true, message: "型号不能为空" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="使用场所" name="place" rules={[{ required: true, message: "使用场所不能为空" }]}>
                                    <Select>
                                        {places?.map((place, index) => (
                                            <Select.Option key={index} value={place.value}>
                                                {place.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="分散介质" name="medium" rules={[{ required: true, message: "分散介质不能为空" }]}>
                                    <Select>
                                        {mediums?.map((medium, index) => (
                                            <Select.Option key={index} value={medium.value}>
                                                {medium.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="防火机理" name="mechanism" rules={[{ required: true, message: "防火机理不能为空" }]}>
                                    <Select>
                                        {mechanisms?.map((mechanism, index) => (
                                            <Select.Option key={index} value={mechanism.value}>
                                                {mechanism.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="耐火性能" name="fireResistance" rules={[{ required: true, message: "耐火性能不能为空" }]}>
                                    <Select>
                                        {fireResistances?.map((fireResistance, index) => (
                                            <Select.Option key={index} value={fireResistance.value}>
                                                {fireResistance.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="密度类型" name="densityType" rules={[{ required: true, message: "密度类型不能为空" }]}>
                                    <Select onChange={onDensitySelectChange}>
                                        {densityTypes?.map((densityType, index) => (
                                            <Select.Option key={index} value={densityType.value}>
                                                {densityType.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="密度" name="density" rules={[{ required: true, message: "密度不能为空" }]}>
                                    <InputNumber style={{ width: '100%' }} addonAfter="g/cm3" />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="col2">
                                <Form.Item label="施工遍数" name="constructionPass" rules={[{ required: true, message: "施工遍数不能为空" }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item label="每遍涂层厚度" name="coatingThickness" rules={[{ required: true, message: "每遍涂层厚度不能为空" }]}>
                                    <InputNumber style={{ width: '100%' }} addonAfter="mm" />
                                </Form.Item>
                                <Form.Item label="规格" name="specification" rules={[{ required: true, message: "规格不能为空" }]}>
                                    <InputNumber style={{ width: '100%' }} addonAfter="kg/桶" />
                                </Form.Item>
                                <Form.Item label="产品特性" name="features">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="价格" name="price">
                                    <InputNumber style={{ width: '100%' }} addonAfter="元" />
                                </Form.Item>
                                <Form.Item label="图片" >
                                    <Upload beforeUpload={beforeUpload} listType="picture-card" fileList={!!selectedCoating?.url ? [{ uid: '1', url: selectedCoating.url, name: "img.png", status: "done" }] : []} onRemove={onRemoveImage} onPreview={() => setPreviewImage(true)}>
                                        {!selectedCoating?.url && <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>上传图片</div>
                                        </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="footer">
                            <Button onClick={() => setDmVis(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" loading={loading} style={{background: '#1271FF'}}>确认</Button>
                            
                        </div>
                    </Form>
                </div>
            </DragModal>}
            <DragModal
                title="预览"
                width={600}
                visible={previewImage}
                onClose={() => setPreviewImage(false)}
                mask
            >
                <div>
                    <img alt="example" style={{ width: '100%' }} src={selectedCoating?.url} />
                </div>
            </DragModal>
        </>

    )
}