import { Paint } from "@/interface/paint";
import axios, { AxiosResponse } from "axios";

export function addPaint(supplierId: number, data: Paint, params?: {}): Promise<AxiosResponse<any>> {
    return axios.post(`suppliers/${supplierId}/paints`, data, {
        params: params
    })
}

export function deletePaint(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.delete(`/paints/${id}`, {
        params: params
    })
}

export function updatePaint(id: number, data: Paint, params?: {}): Promise<AxiosResponse<any>> {
    return axios.put(`/paints/${id}`, data, {
        params: params
    })
}

export function getPaintDetail(id: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/paints/${id}`, {
        params: params
    })
}

export function getPaints(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/paints`, {
        params: params
    })
}