
import GoodsCoating from "./goods-coating";
import GoodsPaint from "./goods-paint";
import GoodsSteel from "./goods-steel";
import cookieStore from "@/store/cookie-store";
import './goods-detail.less'
import { useEffect, useMemo, useState } from "react";
import { getSupplier } from "@/api/supplier";

import { Steel } from "@/interface/steel";

interface Iprops {
    supplierId: number;
}

export default function GoodsDetail(props: Iprops) {
    const [steels, setSteels] = useState<Steel[]>();
    const [name, setName] = useState<string>();
    const user = cookieStore.getUser();
    const [nowGoodsTab, setNowGoodsTab] = useState<number>(1);
    useEffect(() => {
        getSupplier(props.supplierId).then(res => {
            if (res.status === 200) {
                setName(res.data.name);
            }
        })
    }, [name])
    
   
    function handleTabChange(num:number){
        //alert(num)
        setNowGoodsTab(num)
    }

    return (
        <div className="good-detail-container">
            {nowGoodsTab===1&&<GoodsSteel supplierId={props.supplierId} onTabChange={handleTabChange}></GoodsSteel>}
            {nowGoodsTab===2&&<GoodsPaint supplierId={props.supplierId} onTabChange={handleTabChange}></GoodsPaint>}
            {nowGoodsTab===3&&<GoodsCoating supplierId={props.supplierId} onTabChange={handleTabChange}></GoodsCoating>}
        </div>
    )
}