import axios, { StdResponse } from "../config/axios"
import { AxiosResponse } from "axios"

export function getUser(userId: number, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/${userId}`, {
        params: params
    })
}


export function sendCode(mobile: String): Promise<StdResponse<any>> {
    return axios.post(`/users/code`, null, {
        params: {
            mobile: mobile,
        }
    })
}


export function updateToken(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/token`, {
        params: {
            clientType: "web",
            ...params,
        }
    })
}

export function telLogin(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/tel-login`, data, {
        params: params
    })
}

export function passwordForget(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/password/forget-web`, data, {
        params: params
    })
}


export function passwordLogin(data: any, params?: {}): Promise<StdResponse<any>> {
    return axios.post(`/users/password-login`, data, {
        params: params
    })
}


export function checkLogin(params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/check-login`, {
        params: params
    })
}
export function checkPassword(password: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/password/check`, {
        params: {
            password: password,
            ...params,
        }
    })
}

// 删除用户
export function deleteUser(userId: number): Promise<AxiosResponse<any>> {
    return axios.delete(`/users/${userId}`)
}

// 手机号验证
export function checkVarification(mobile: string, code: string, params?: {}): Promise<AxiosResponse<any>> {
    return axios.get(`/users/code/check`, {
        params: {
            mobile: mobile,
            value: code,
            ...params
        }
    })
}