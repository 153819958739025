import { Tabs , Input, Button, List, Card, Image, Modal } from "antd";
import  {EnvironmentOutlined, PhoneOutlined, DownloadOutlined, UserOutlined, MobileOutlined, EditOutlined, PlusOutlined, UploadOutlined}  from "@ant-design/icons";
import './detail.less';
import  './supplier-info-censored.less';
import  './supplier-info-uncensored.less';
import { Supplier } from "@/interface/supplier";
import React, { useEffect, useState, useContext } from "react";
import cookieStore from "@/store/cookie-store";
import { getSupplier, inspectSupplier, updateSupplier } from "@/api/supplier";
import Upload from "antd/lib/upload/Upload";
import { getFileByMd5, getPresignedPutObjectUrl } from "@/api/file";
import { FileUploadDto } from "@/interface/file";
import { UploadFileContext } from '@/App';
import { FileUploadParam, UploadFileLocale } from 'tncet-common';
import { SuppliesTypes } from "@/enums/supplies-type";
import { Route } from "react-router-dom";
import SupplierDetail from "./detail";
import { User } from "@/interface/user";

const {Meta} = Card;


const {TextArea} = Input;
export default function SupplierInfo(props: any){

    //通用
    const iniSupply = {id: 0}
    const [supply, setSupply] = useState<Supplier>(iniSupply);

    const [currUrl, setCurrUrl] = useState<string>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    //商品种类
    const [supplies, setSupplies] = useState<string>(null);

    let id = props.supplierId;

    useEffect(()=>{
        if (id != undefined){
            let user: User = cookieStore.getUser()
            setIsAdmin(user.type===0);

            getSupplier(id).then(res=>{
                setSupply(res.data);
                // console.log(res.data.userId === user.id)
                setCanEdit(res.data.userId === user.id && 
                    (res.data.status === 1 || res.data.status === 3 ))
                setLegalRepresent(res.data.legalRepresent)
                setIdentityNumber(res.data.identityNumber)
                let types:number[] = res.data.types
                let tmp = ''
                types.forEach(v=>{tmp = tmp + SuppliesTypes(v)+ " | "})
                setSupplies(tmp.slice(0, tmp.length-2))

                if (res.data.licenseMd5){
                    getFileByMd5(res.data.licenseMd5).then(res=>{
                        let url = res.data.url;
                        if (url){
                            setCurrUrl(url)
                        }
                    })
                }

            })
        }
    },[id])

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isCensored, setIsCensored] = useState<boolean>(false)
    useEffect(()=>{
        setIsCensored(supply?.status===4)
    },[supply])

    
    const download = url => {
        fetch(url, {
          method: "GET",
          headers: {}
        })
          .then(response => {
            response.arrayBuffer().then(function(buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", supply.licenseMd5+supply.licenseSuffix); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
      };
  

    const SupplierInfoCensored = <div className="supplierInfoCensored">
        <div className="titleLine">
            <span className="title">{supply.name}&nbsp;
            {canEdit&&<EditOutlined onClick={()=>{
                setIsModalOpen(true)
            }}/>}</span>
            <Modal bodyStyle={{padding: "20px 30px"}} title="修改提示" open={isModalOpen} 
            onOk={()=>{setIsCensored(false) ;setIsModalOpen(false)}} onCancel={()=>setIsModalOpen(false)}>
                <p>您的商家信息已经认证通过，此时编辑信息需要重新认证。是否确认编辑？</p>
            </Modal>
        </div>
        <div className="attrLine">
            <span>
                <EnvironmentOutlined />&nbsp;&nbsp;{supply.address} 
            </span>
        </div>
        <div className="attrLine">
            <span>
                <PhoneOutlined/>&nbsp;&nbsp;{supply.contactMobile}（{supply.contactName}）
            </span>
        </div>
        <div className="attrLine">
            <span>
                <DownloadOutlined/>&nbsp;&nbsp;营业执照{currUrl?<a onClick={()=>{
                    download(currUrl)
                }}>（点击查看）</a>:<span>（暂未上传）</span>}
            </span>
        </div>
        <div className="attrLine">
            <span>
                <UserOutlined/>&nbsp;&nbsp;法人：{supply.legalRepresent}
            </span>
        </div>
        <div className="licenseLine">
            {currUrl&&<Image src={currUrl} width={200} height={200}>营业执照</Image>}
        </div>
        
    </div>

    //引入上下文
    const uploadFileContext = useContext(UploadFileContext);

    //上传文件的回调函数
    const uploadCallbackFn = (uploadFile: FileUploadParam) => {
        return new Promise(resolve => {
           

            let newSupplier = supply;
            let oriFile : FileUploadDto = {
                fileMD5: uploadFile.md5,
                suffix: uploadFile.suffix,
                filePath: uploadFile.filePath,
                fileSize: uploadFile.fileSize
            }
            newSupplier.licenseMd5 = uploadFile.md5;
            newSupplier.licenseSuffix = uploadFile.suffix;
            // 更新originfile
            newSupplier.file = oriFile;
            updateSupplier(supply.id, newSupplier).then(res=>{
                // 更新显示图片
                getFileByMd5(uploadFile.md5).then(res=>{
                    setCurrUrl(res.data.url)
                    resolve("")
                })
            })

        })
    }

    //上传文件后将文件储存至此
    let [uploadFileList, setUploadFileList] = useState([]);

    //当上传文件列表发生改变时，调用上传文件api开始上传
    useEffect(() => {
        if (uploadFileList.length === 0) return;
        let files: UploadFileLocale[] = uploadFileList.map(item => {
            return {
                file: item,
                callbackFn: uploadCallbackFn
            }
        })
        uploadFileContext.startUpload(files); //上传的关键
    }, [uploadFileList])

    //在执行antd本身组件Upload的上传文件功能之前所做的事
    //这里需要禁止antd本身组件Upload的上传行为，因此必须返回false
    const beforeUpload = (file, fileList) => {
        if (uploadFileList.length === 0) {
            setUploadFileList([...fileList]);
        }else{
            if(file){
                if (file.type !== "image/jpeg" && file.type !== "image/png"){
                    alert("请选择jpg或png格式的图片！")
                    return false;
                }
                if (file.size > 5*1024*1024){
                    alert("图片大小请在5M之内")
                    return false;
                }
                // console.log(file)
                setUploadFileList([file])
            }
        }
        //必须返回false，以阻止antd组件自身上传行为
        return false;
    }

    const [legalRepresent, setLegalRepresent] = useState<string>('')
    const [identityNumber, setIdentityNumber] = useState<string>('')
    
    const [inspecting, setInspecting] = useState<boolean>(false);
    const [suggestion, setSuggestion] = useState<string>('');

    function handleRelease(){
        inspectSupplier(id, false, suggestion);
        window.location.reload();
    }

    const SupplierInfoUncensored = <div className="supplierInfoUncensored">
    <div className="titleLine">
        <span>
            {supply.status===1&&'申请认证'}
            {supply.status===2&&'认证审核中'}
            {supply.status===3&&'认证修改'}
        </span>
    </div>
    <div className="supplierInfoPage">
        
        <div className="disabledModule">
            <div className="mainTextLine">
                <span>商家信息（如需修改，请在移动端进行操作）</span>
                <div className="leftbar"></div>
            </div>
            <div className="infoTextLine">
                <span>
                    公司名称：{supply.address}
                </span>
            </div>
            <div className="infoTextLine">
                <span>
                    公司地址：{supply.name}
                </span>

            </div>
            <div className="infoTextLine">
                <span>
                    经营业务：{supplies}
                </span>

            </div>
            <div className="infoTextLine">
                <span>
                    联 系 人&nbsp;：{supply.contactName}
                </span>

            </div>
            <div className="infoTextLine">
                <span>
                    联系方式：{supply.contactMobile}
                </span>

            </div>
        </div>
        <div className={canEdit?"enabledModule":"disabledModule"}>
            <div className="mainTextLine">
                <span>法人代表</span>
                <div className="leftbar"></div>
            </div>
            <div className="inputLine">
                <div className="inputField">
                    <span>法人姓名：&nbsp;&nbsp;</span>
                    <Input className="input" placeholder="请输入姓名" value={legalRepresent} disabled={!canEdit}
                    onChange={(e)=>{
                        setLegalRepresent(e.target.value)
                    }}/>
                </div>
                <div className="inputField">
                    <span>身份证号：&nbsp;&nbsp;</span>
                    <Input className="input" placeholder="请输入18位身份证号" value={identityNumber} disabled={!canEdit}
                    onChange={(e)=>{
                        setIdentityNumber(e.target.value)
                    }}/>
                </div>
                
            </div>
        </div>
        <div className={canEdit?"enabledModule":"disabledModule"}>
            <div className="mainTextLine">
                <span>营业执照</span>
                <div className="leftbar"></div>
            </div>
            <div className="imageField">
                <span>选择图片</span>
                
                <Upload accept=".jpg, .png"
                showUploadList={false}
                maxCount={1}
                beforeUpload={beforeUpload}
                disabled={!canEdit}
                
                >
                    <Image className={canEdit?"uploadImage":"disabledUploadImage"} src={currUrl||(canEdit?"/assets/上传图片.png":"/assets/禁止上传图片.png")} width={100} height={75} preview={false}/>
                </Upload>
                {/* <UploadButton uploadFileContext={UploadFileContext} uploadCallbackFn={uploadCallbackFn}/> */}
            </div>
        </div>
    </div>
    <div className="submitButtonLine">
        {(isAdmin&&supply.status===2)&&<>
        <Button className="submitButton" style={{backgroundColor: '#ff0000'}} onClick={()=>{
            setInspecting(true)
        }}><span>驳回申请</span></Button>
        <Button className="submitButton" onClick={()=>{
            inspectSupplier(id, true);
            window.location.reload();
        }}><span>通过申请</span></Button>
        <Modal className="inspect-panel" title={
                <div className="title" style={{fontWeight: 'bolder', textAlign: 'center',borderTop: 'none', fontSize: '22px'}}>驳回申请</div>
                } open={inspecting} onCancel={()=>{setInspecting(false)}}
                footer={
                    <div>
                        <div style={{ textAlign: 'center', marginTop: '5px'}}>
                        <Button key="ok" onClick={handleRelease} style={
                            {width: '146px',
                            height: '35px',
                            borderRadius: '4px',
                            background: '#1271FF',
                            boxShadow: '-4px 4px 4px 0px rgba(122, 171, 204, 0.05)'
                        }}>
                        <span style={{
                            color: "#FFF",
                            fontFamily: "'Hiragino Sans GB', sans-serif",
                            fontSize: "20px",
                            fontWeight:"600",
                            display: 'block',
                            marginTop: '-3px'
                        }}>确认</span>
                        </Button>
                        </div>
                    </div>
                    
                } >
                    <TextArea className="textarea" style={{
                        marginBottom: '3px',
                        height: '200px',
                        backgroundColor: '#F3F3F3',
                        resize: 'none'
                    }} placeholder="请输入修改建议"
                    maxLength={200}
                    onChange = {(e)=>{
                        setSuggestion(e.target.value);
                    }}
                    ></TextArea>
                    
            </Modal>
        </>}
        {(!isAdmin&&canEdit)&&<Button className="submitButton"
        onClick={()=>{
            
            let newSupplier: Supplier = {
                id: id,
                legalRepresent: legalRepresent,
                identityNumber: identityNumber,
                status: 2
            }
            updateSupplier(id, newSupplier).then(res=>{
                window.location.reload()
            })
        }}
        ><span>提交申请</span></Button>}
    </div>
    </div>

    return <>{isCensored?SupplierInfoCensored:SupplierInfoUncensored}
    </>;
}