import { User } from "../interface/user";

const GLOBAL_NAMESPACE = 'tnsection';
export const REMEMBER_TELEPHONE = `${GLOBAL_NAMESPACE}_login_telephone`;
export const REMEMBER_PASSWORD = `${GLOBAL_NAMESPACE}_pmeta`;
export const REMEMBER_TAG = `${GLOBAL_NAMESPACE}_remember_flag`;


class AppStore {
    // private _token = '';
    // private _user: user = null;

    public get(key: string) {
        let value = localStorage.getItem(key)
        if (value == '0') {
            return JSON.parse(value)
        }
        return JSON.parse(value || 'null') || null;
    }

    public set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    // get token(): string {
    //     // if (!this._token) {
    //     //     this._token = this.get(AUTHORIZATION) || '';
    //     // }
    //     this._token = this.get(TOKEN) || '';
    //     return this._token;
    // }

    // set token(value: string) {
    //     this.set(TOKEN, value);
    // }

    // get user(): user {
    //     if (!this._user) {
    //       this._user = this.get(USER) || null;
    //     }

    //     return this._user;
    //   }

    // set user(value: user) {
    //     this._user = value;
    //     this.set(USER, this._user);
    // }

    clear() {
        // this._token = '';
        // this._user = null;
        // this.remove(TOKEN);
        // this.remove(USER);
        // this.remove(USER_ID);
    }
}

export default new AppStore();