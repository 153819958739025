import axios from "../config/axios";
import qs from 'qs';
import { Evaluation } from "@/interface/evalutaion";


export function getEvaluations(supplierId: number) {

    return axios.get(`/suppliers/evaluations`, {
        params: { id: supplierId },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' });
        }
    });
}

export function checkEvaluation(content: string){
    return axios.get(`/evaluation/content`, {
        params: {content: content},
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    });
}

export function addEvaluation(supplierId: number, evalutaion: Evaluation){
    
    return axios.post(`/suppliers/${supplierId}/evaluations`, evalutaion);
}
